import { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ShareIcon } from 'common/icons_V2/ShareIcon'
import { Typography, TypographyVariants } from 'common/typography'
import { Base } from 'features/MyProfile/components/ProfilePopup/Base'
import { copyShareLink } from 'features/PublicTokens/actions'
import { PROFILE_ACTIONS_KEYS } from 'features/Translations/constants'
import { selectOtherProfileTranslations } from 'features/Translations/selectors'

interface TShare {
  uid: string;
  closePopup: () => void;
  alertTrigger: () => void;
}

export const Share: FC<TShare> = ({
  uid, closePopup, alertTrigger
}) => {
  const dispatch = useDispatch()
  const otherProfileTranslations = useSelector(selectOtherProfileTranslations)

  return (
    <Base
      closePopup={closePopup}
      onClick={() => {
        dispatch(copyShareLink(uid))
        alertTrigger()
      }}
    >
      <ShareIcon />
      <Typography variant={TypographyVariants.Desktop_UI_S_Regular} tag="p">
        {otherProfileTranslations[PROFILE_ACTIONS_KEYS.SHARE]}
      </Typography>
    </Base>
  )
}
