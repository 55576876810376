import { FC } from 'react'
import { Image } from 'common/components/Image'
import { Modal } from 'features/Modal'
import styles from './styles.module.sass'

interface IPhotoModal {
  isOpen: boolean,
  onClose: () => void,
  photoURL?: string,
}

export const PhotoModal: FC<IPhotoModal> = ({
  isOpen,
  onClose,
  photoURL
}) => (
  <Modal
    isOpen={isOpen}
    width="752px"
    onClose={onClose}
    isClosable
  >
    <div className={styles.viewPhoto}>
      <Image
        photoURL={photoURL || ''}
        width={360}
      />
    </div>
  </Modal>
)
