import { useState } from 'react'
import { useSelector } from 'react-redux'
import { ExploreSuccessModal } from 'features/MyProfile/components/VacancySpeciality_V2/ExploreSuccessModal'
import { ManageVacancyModal } from 'features/MyProfile/components/VacancySpeciality_V2/ManageVacancyModal'
import { selectHomeTranslations } from 'features/Translations/selectors'

interface IVacancyModalWithOkButton {
  isOpen: boolean,
  onClose: (limitReached: boolean) => void,
  vacancyId?: string,
}

export const ManageVacancyModalWithOkButton = ({ isOpen, onClose, vacancyId = '' }: IVacancyModalWithOkButton) => {
  const homeTranslations = useSelector(selectHomeTranslations)
  const [isOpenExploreSuccesModal, setIsOpenExploreSuccessModal] = useState(false)

  return (
    <>
      {isOpen && (
        <ManageVacancyModal
          isOpen={isOpen}
          onClose={onClose}
          onOpenSuccessModal={() => setIsOpenExploreSuccessModal(true)}
          vacancyId={vacancyId}
        />
      )}
      <ExploreSuccessModal
        message={homeTranslations.exploreHiringCreatedAlertMessage}
        iconColor="#9747FF"
        isOpen={isOpenExploreSuccesModal}
        onClose={() => setIsOpenExploreSuccessModal(false)}
      />
    </>
  )
}
