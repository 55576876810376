import {
  FC, useCallback, useEffect, useState
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ReactTooltip from 'react-tooltip'
import cn from 'classnames'
import { GhostButton } from 'common/components/Button_V2/GhostButton'
import { TrustingList } from 'common/components/EmptySearchState/TrustingList'
import { ImageWithTrustMark } from 'common/components/ImageWithTrustMark_V2'
import { useProfileNavigation } from 'common/hooks/useProfileNavigation'
import { Typography, TypographyVariants } from 'common/typography'
import { colorTheMatch } from 'common/utils/colorTheMatch'
import { formatUserName } from 'common/utils/formatUserName'
import { searchInString } from 'common/utils/searchInString'
import { FilterItems } from 'features/FilterItems_V2'
import { actions as actionsFilter } from 'features/FilterItems_V2/actions'
import { selectNetworkModalFilters } from 'features/FilterItems_V2/selectors'
import { StateType } from 'features/FilterItems_V2/types'
import { Modal } from 'features/Modal_V2'
import { addContact } from 'features/MyProfile/actions'
import { getMyUid, selectMyNetworkContacts, selectTrustLevels } from 'features/MyProfile/selectors'
import type { ContactsType, ProfileType } from 'features/MyProfile/types'
import { SearchInput } from 'features/SearchInput_V2'
import { selectMyNetworkTranslations, selectOtherProfileTranslations } from 'features/Translations/selectors'
import { NetworkModalFilters } from 'features/TrustUsersModal/types'
import styles from './styles.module.sass'

interface IOtherProfileTrustUsersModal {
  trustedContactsOtherProfile: ContactsType[]
  trustingContactsOtherProfile: ContactsType[]
  isOpenTrustModal: boolean
  onClose: () => void
  profile: ProfileType
}

export const OtherProfileTrustUsersModal: FC<IOtherProfileTrustUsersModal> = ({
  trustedContactsOtherProfile,
  trustingContactsOtherProfile,
  isOpenTrustModal,
  onClose,
  profile
}) => {
  const dispatch = useDispatch()
  const myNetworkTranslations = useSelector(selectMyNetworkTranslations)
  const otherProfileTranslations = useSelector(selectOtherProfileTranslations)
  const trustLevels = useSelector(selectTrustLevels)
  const networkModalFilters = useSelector(selectNetworkModalFilters)
  const contacts = useSelector(selectMyNetworkContacts)
  const [searchString, setSearchString] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const myUid = useSelector(getMyUid)
  const [filteredContacts, setFilteredContacts] = useState<ContactsType[]>([])
  const { navigateToProfile } = useProfileNavigation()

  const handleSetActiveFilters = (filters: number[]) => {
    dispatch(actionsFilter.setNetworkModalFilters({ networkModalFilters: filters }))
  }

  const getNetworkModalFilters = () => {
    const filters = []
    if (trustedContactsOtherProfile.length) {
      filters.push({ id: NetworkModalFilters.TRUSTED, name: myNetworkTranslations.networkModalFiltersTrusted })
    }
    if (trustingContactsOtherProfile.length) {
      filters.push({ id: NetworkModalFilters.TRUSTING, name: myNetworkTranslations.networkModalFiltersTrusting })
    }
    return filters
  }

  const onSearch = (value: string) => {
    const isTrustedFilterActive = networkModalFilters?.includes(NetworkModalFilters.TRUSTED)
    const contactsToFilter = isTrustedFilterActive
      ? trustedContactsOtherProfile
      : trustingContactsOtherProfile
    const filteredUsers = value.trim()
      ? contactsToFilter.filter(({ displayName }) => searchInString(value, displayName || ''))
      : contactsToFilter
    setFilteredContacts(filteredUsers)
    setSearchString(value)
  }

  useEffect(() => {
    const isTrustedFilterActive = networkModalFilters?.includes(NetworkModalFilters.TRUSTED)
    const contactsToShow = isTrustedFilterActive
      ? trustedContactsOtherProfile
      : trustingContactsOtherProfile

    if (searchString.trim()) {
      const filteredUsers = contactsToShow.filter(
        ({ displayName }) => searchInString(searchString, displayName || '')
      )
      setFilteredContacts(filteredUsers)
    } else {
      setFilteredContacts(contactsToShow)
    }
  }, [trustedContactsOtherProfile, trustingContactsOtherProfile, networkModalFilters, searchString])

  const sortContacts = useCallback((contactsData: ContactsType[]) => {
    return contactsData.sort((a, b) => {
      const trustLevelA = trustLevels[a.uid] || 0
      const trustLevelB = trustLevels[b.uid] || 0
      const isExistingContactA = contacts.some((contact) => contact.uid === a.uid) || a.uid === myUid
      const isExistingContactB = contacts.some((contact) => contact.uid === b.uid) || b.uid === myUid

      if (isExistingContactA && !isExistingContactB) return -1
      if (!isExistingContactA && isExistingContactB) return 1

      if (trustLevelA < trustLevelB) return -1
      if (trustLevelA > trustLevelB) return 1

      return a.displayName.localeCompare(b.displayName)
    })
  }, [trustLevels, contacts])

  const onClick = async (uid: string, isExistingContact: boolean) => {
    try {
      setIsLoading(true)
      await dispatch(addContact(uid, isExistingContact))
    } finally {
      setIsLoading(false)
    }
  }

  const handleClose = () => {
    setSearchString('')
    onClose()
  }
  return (
    <Modal
      isOpen={isOpenTrustModal}
      width="460px"
      height="510px"
      title={`${otherProfileTranslations.trustingModalTitle || ''}`.replace('%@', formatUserName(profile))}
      onClose={handleClose}
      footerStyles={styles.footerStyles}
      scrollableContent={(
        <div className={styles.otherProfileTrusts}>
          {filteredContacts.length ? (
            <>
              <Typography variant={TypographyVariants.Desktop_UI_L_Medium} tag="h3" className={styles.subtitle}>
                {
                  networkModalFilters?.includes(NetworkModalFilters.TRUSTED)
                    ? `${myNetworkTranslations.networkModalFiltersTrusted}`
                    : `${myNetworkTranslations.networkModalFiltersTrusting}`
                }
              </Typography>
              <div className={styles.contacts}>
                {sortContacts(filteredContacts).map((user: ContactsType) => {
                  const {
                    displayName, photoURL, uid, job_title: job, photo, username
                  } = user
                  const isExistingContact = contacts.some((contact) => contact.uid === uid)
                  return (
                    <div
                      key={uid}
                      onClick={() => {
                        onClose()
                        navigateToProfile(uid, username)
                      }}
                      className={styles.contact}
                    >
                      <ImageWithTrustMark
                        photo={photo}
                        photoURL={photoURL}
                        alt={displayName}
                        width={56}
                        className={styles.imgContainer}
                        uid={uid}
                      />
                      <div className={styles.data}>
                        <div>
                          <Typography
                            variant={TypographyVariants.Desktop_UI_M_Medium}
                            tag="p"
                            className={styles.userName}
                          >
                            {colorTheMatch(displayName, searchString)}
                          </Typography>
                          <Typography
                            variant={TypographyVariants.Desktop_UI_S_Regular}
                            tag="p"
                            className={styles.position}
                          >
                            {colorTheMatch(job, searchString)}
                          </Typography>
                          <ReactTooltip id={job} place="top" effect="solid" className={styles.tooltip}>
                            {job}
                          </ReactTooltip>
                        </div>
                        {!isExistingContact && uid !== myUid && (
                          <GhostButton
                            title={myNetworkTranslations.networkModalAddButton}
                            className={cn(styles.button, styles.introduceButton)}
                            onClick={(e) => {
                              e.stopPropagation()
                              onClick(uid, isExistingContact)
                            }}
                            disabled={isLoading}
                          />
                        )}
                      </div>
                    </div>
                  )
                })}
              </div>
            </>
          ) : (
            <TrustingList />
          )}
        </div>
      )}
    >
      <div className={styles.searchFilters}>
        <SearchInput
          onChange={onSearch}
          value={searchString}
          placeholder={
            `${otherProfileTranslations.trustListSearchPlaceholder || ''}`
              .replace('%@', String(filteredContacts?.length || 0))
          }
        />
        <FilterItems
          items={getNetworkModalFilters()}
          setActiveFilters={handleSetActiveFilters}
          activeFilters={networkModalFilters}
          allowMultiple={false}
          state={StateType.LIGHT}
          defaultFilter={NetworkModalFilters.TRUSTED}
        />
      </div>
    </Modal>
  )
}
