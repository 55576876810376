import type { ThunkAction } from 'redux-thunk'
import type { Auth } from 'firebase/auth'
import { AppStateType, InferActionsTypes } from 'common/types'
import { actions } from 'features/FilterItems_V2/actions'

export type ActionTypes = InferActionsTypes<typeof actions>
export type ThunkType = ThunkAction<Promise<void | boolean> | void, AppStateType, Auth, ActionTypes>

export type FilterType = object

export enum StateType {
    DEFAULT = 1,
    LIGHT = 2,
    ACTIVE = 3
}
