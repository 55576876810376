import { useMemo } from 'react'
import { UseFormReset } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { FIELDS } from 'common/enums'
import { useVacancyDetails } from 'common/hooks/useVacancyDetails'
import { addVacancy, editVacancy } from 'features/MyProfile/actions'
import { VacancySpecialityModalWithDiscard } from 'features/MyProfile/components/VacancySpeciality_V2/VacancySpecialityModal'
import { IVacancySpecialityForm, normalizeData } from 'features/MyProfile/components/VacancySpeciality_V2/validator'
import { getMyUid } from 'features/MyProfile/selectors'
import { selectProfileTranslations } from 'features/Translations/selectors'

interface IManageVacancyModal {
  isOpen: boolean;
  vacancyId?: string;
  onClose: (limitReached: boolean) => void;
  onOpenSuccessModal?: () => void;
}

export const ManageVacancyModal = ({
  isOpen,
  onClose,
  vacancyId = '',
  onOpenSuccessModal
}: IManageVacancyModal) => {
  const dispatch = useDispatch()
  const profileTranslations = useSelector(selectProfileTranslations)
  const myUid = useSelector(getMyUid)
  const vacancyDetails = useVacancyDetails(myUid, vacancyId)
  const isOnEditing = !!vacancyId

  const initialInputValues: IVacancySpecialityForm = useMemo(() => ({
    [FIELDS.JOB_NAME]: vacancyDetails.name ?? '',
    [FIELDS.LOCATIONS]: vacancyDetails.locations ?? [],
    [FIELDS.JOB_TYPE]: vacancyDetails.type ?? '',
    [FIELDS.ABOUT_JOB]: vacancyDetails.details.description ?? '',
    [FIELDS.TAGS]: vacancyDetails.tags ?? [],
    [FIELDS.LOCATION_TYPE]: {
      remote: vacancyDetails.details.remote,
      onsite: vacancyDetails.details.onsite,
      hybrid: vacancyDetails.details.hybrid
    },
    [FIELDS.CURRENCY]: vacancyDetails.details.currency ?? '',
    [FIELDS.SALARY_MAX]: vacancyDetails.details.salaryMax ?? null,
    [FIELDS.SALARY_MIN]: vacancyDetails.details.salaryMin ?? null,
    [FIELDS.RATE]: vacancyDetails.details.payPeriod ?? '',
    [FIELDS.AUDIENCE]: vacancyDetails.audience ?? '',
    [FIELDS.URL]: vacancyDetails.url ? [{ url: vacancyDetails.url }] : []
  }), [vacancyId])

  const uploadVacancy = (data: any, resetFormData: UseFormReset<any>) => {
    if (vacancyId && vacancyDetails.status) {
      return dispatch(editVacancy(
        vacancyId,
        normalizeData(data),
        vacancyDetails.status,
        () => onClose(false)
      ))
    }

    return dispatch(addVacancy(
      normalizeData(data),
      {
        onFinish: (isSuccess: boolean, limitReached: boolean) => {
          resetFormData()
          if (isSuccess) onOpenSuccessModal?.()
          onClose(limitReached)
        }
      }
    ))
  }

  return (
    <VacancySpecialityModalWithDiscard
      title={profileTranslations.hiring}
      initialInputValues={initialInputValues}
      isOnEditing={isOnEditing}
      isEditable={!isOnEditing}
      isOpen={isOpen}
      onClose={onClose}
      uploadFormData={uploadVacancy}
    />
  )
}
