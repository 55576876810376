import {
  FC,
  ReactElement,
  useRef
} from 'react'
import cn from 'classnames'
import {
  autoUpdate,
  flip,
  Placement,
  useFloating
} from '@floating-ui/react-dom'
import { useOutside } from 'common/hooks/useOutside'
import { ArrowBottomIcon } from 'common/icons/ArrowBottomIcon'
import { PreloaderIcon } from 'common/icons/PreloaderIcon'
import { ProfileSection } from 'features/NavBar/components/DropDownButton/ProfileSection'
import { DropDownItemType } from 'features/NavBar/types'
import styles from './styles.module.sass'

interface IDropDownButton {
  icon: ReactElement | null
  list: DropDownItemType[]
  isActive?: boolean
  arrow?: boolean
  isOpenList: boolean
  onCloseList: () => void
  onToggleOpenList?: () => void
  listWidth?: number | string
  className?: string;
  dynamicPosition?: boolean;
  dropdownPlacement?: Placement;
  isMobileScreen?: boolean
  closeSideBar?: () => void
}

export const DropDownButton: FC<IDropDownButton> = ({
  icon,
  list,
  isActive,
  arrow = true,
  isOpenList,
  onCloseList,
  onToggleOpenList,
  listWidth = 'unset',
  className,
  dynamicPosition = false,
  dropdownPlacement,
  isMobileScreen = false,
  closeSideBar
}) => {
  const { refs, floatingStyles } = useFloating({
    placement: dropdownPlacement,
    middleware: [flip()],
    whileElementsMounted(...args) {
      const cleanup = autoUpdate(...args)
      return cleanup
    }
  })

  const floatingContentStyles = dynamicPosition ? floatingStyles : {}

  const dropDownListEventRef = useRef(null)
  useOutside(dropDownListEventRef, onCloseList)
  const currentList = list.map(({
    icon,
    title,
    onClick,
    isLoading,
    textColor
  }) => {
    return (
      <div
        onClick={() => {
          onClick?.()
          onCloseList()
        }}
        key={title}
        className={styles.dropDownItem}
      >
        {isLoading ? <PreloaderIcon stroke="#ccd5e4" /> : icon}
        <p className={styles.title} style={{ color: textColor }}>{title}</p>
      </div>
    )
  })

  return (
    <div className={cn(styles.dropDownButton, isMobileScreen && styles.border)} ref={dropDownListEventRef}>
      {!!icon && (
        <div
          className={cn(
            styles.button,
            !isMobileScreen && styles.defaultStyles,
            isOpenList && styles.openDropDownList,
            isActive && styles.active
          )}
          onClick={isMobileScreen ? undefined : onToggleOpenList}
        >
          {icon}
          {arrow && <div className={styles.arrow}><ArrowBottomIcon /></div>}
        </div>
      )}
      {isOpenList && (
        <div
          className={cn(styles.dropDownContainer,
            !isMobileScreen && styles.isFullScreen, className)}
          ref={dynamicPosition ? refs.setFloating : null}
          style={{ width: listWidth, ...floatingContentStyles }}
        >
          <ProfileSection
            onAfterClick={() => {
              onCloseList()
              closeSideBar?.()
            }}
          />
          {currentList}
        </div>
      )}
    </div>
  )
}
