import {
  forwardRef, PropsWithChildren, useEffect, useState
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import cn from 'classnames'
import { ROUTES } from 'common/constants'
import { getUserDetailsPath } from 'common/utils/url'
import { getAuth } from 'features/Auth/selectors'
import { addMemoizedUser, getUserVacancies } from 'features/Home/actions'
import { selectMemoizedUserById } from 'features/Home/selectors'
import { getCommonTrustedContactList, getSpecialties, getTrustedContactList } from 'features/MyProfile/actions'
import { HeaderMyProfile } from 'features/MyProfile/components/ProfilePage/HeaderMyProfile'
import { HeaderOtherProfile } from 'features/MyProfile/components/ProfilePage/HeaderOtherProfile'
import { Links } from 'features/MyProfile/components/ProfilePage/Links'
import { ProfileType } from 'features/MyProfile/types'
import { setPublicTokenByUid } from 'features/PublicTokens/actions'
import { selectProfileTranslations } from 'features/Translations/selectors'
import { useProfileSections } from 'providers/ProfileSectionsProvider'
import styles from './styles.module.sass'

interface IBaseProfile {
  openedProfile: ProfileType,
  isOtherProfile?: boolean
  basePath: string
}

export const BaseProfile = forwardRef<HTMLDivElement | null, PropsWithChildren<IBaseProfile>>(({
  openedProfile,
  isOtherProfile = false,
  basePath,
  children
}, ref) => {
  const { activeSection } = useProfileSections()
  const disable = !!activeSection
  const dispatch = useDispatch()
  const history = useHistory()
  const { pathname } = useLocation()
  const isHomePage = basePath === ROUTES.USER_ROUT
  const profileTranslations = useSelector(selectProfileTranslations)
  const auth = useSelector(getAuth)
  const [isLoading, setIsLoading] = useState(false)
  const { job } = openedProfile

  const [,, openedProfileUid] = pathname.split('/')
  const memoizedUser = useSelector(selectMemoizedUserById(openedProfileUid))

  useEffect(() => {
    if (openedProfileUid) {
      dispatch(getUserVacancies(openedProfileUid))
      dispatch(getSpecialties(openedProfileUid))
      dispatch(getTrustedContactList(openedProfileUid))
      dispatch(setPublicTokenByUid(openedProfileUid))
      dispatch(getCommonTrustedContactList())
    }
  }, [openedProfileUid])

  useEffect(() => {
    if (openedProfileUid) {
      dispatch(addMemoizedUser(openedProfileUid, true, {}))
    }
  }, [openedProfileUid, memoizedUser])

  const jobAndCompany = job?.company
    ? `${job?.title || ''} ${profileTranslations.prepositionAt} ${job.company}`
    : job?.title || ''

  useEffect(() => {
    if (openedProfile?.username) {
      const userPath = getUserDetailsPath(openedProfile.username)
      if (pathname !== userPath) history.replace(userPath)
    }
  }, [openedProfile?.username, pathname, history])

  return (
    <>
      <div className={styles.container}>
        <div className={cn(styles.infoContainer, disable && styles.transparent)} ref={ref}>
          <div className={styles.personalInfo}>
            {isOtherProfile ? (
              <HeaderOtherProfile
                auth={!!auth}
                profile={openedProfile}
                isHomePage={isHomePage}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                jobAndCompany={jobAndCompany}
              />
            ) : (
              <HeaderMyProfile
                profile={openedProfile}
                jobAndCompany={jobAndCompany}
                auth={!!auth}
              />
            )}
            <Links links={openedProfile.links || []} />
          </div>
        </div>
        {children}
      </div>
    </>
  )
})
