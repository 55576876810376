import type { ConfirmationResult } from 'firebase/auth'
import { signInWithPhoneNumber as signInWithPhoneNumberFirebase } from 'firebase/auth'
import { profileAPI, profileAPIV2, usersAPI } from 'api'
import { actions as actionsApp, actions as commonActions } from 'common/actions'
import { ROUTE_SEARCH_PARAMS, ROUTES } from 'common/constants'
import LocalStorageService from 'common/utils/LocalStorageService'
import { RecaptchaSingleton } from 'common/utils/RecaptchaSingleton'
import {
  getQueryParamsFromUrl, getUserDetailsPath, getUserSpecialtyDetails, getUserVacancyDetails
} from 'common/utils/url'
import envConfig from 'config'
import { AppEnv } from 'config/type'
import type { ThunkType } from 'features/Auth/types'
import { init as initConversations } from 'features/Conversations/actions'
import { actions as actionsFilter } from 'features/FilterItems_V2/actions'
import { actions as actionsHome } from 'features/Home/actions'
import { MyListingsFilters } from 'features/Listing/types'
import {
  actions as profileActions,
  getHomeCardsOptimized,
  init as initProfile
} from 'features/MyProfile/actions'
import { getTokenFcm } from 'features/MyProfile/utils'
import { actions as actionsNotifications } from 'features/Notifications/actions'
import { ErrorModalTypes } from 'features/Notifications/types'
import { firebaseAuth } from 'store/store'

const isDev = envConfig.env === AppEnv.DEV

export const actions = {
  setAuth: (auth: boolean) => ({ type: 'AUTH__SET_AUTH', auth } as const),
  setSpecialties: (specialties: string[]) => ({ type: 'AUTH__SET_SPECIALTIES', specialties } as const),
  setConfirmation: (confirmation: ConfirmationResult | null) => (
    { type: 'AUTH__SET_CONFIRMATION', confirmation } as const
  ),
  setRegisterData: (payload: { number: string }) => (
    { type: 'AUTH__SET_REGISTER_DATA', payload } as const
  ),
  setSignUpData: (payload: { number: string }) => (
    { type: 'AUTH__SET_SIGN_UP_DATA', payload } as const
  ),
  setIsFailedConfirmationCode: (isFailedConfirmationCode: boolean) => (
    { type: 'AUTH__SET_IS_FAILED_CONFIRMATION_CODE', isFailedConfirmationCode } as const
  ),
  setIsLoading: (isLoading: boolean) => ({ type: 'AUTH__SET_IS_LOADING', isLoading } as const),
  setIsWaitingProfileData: (isWaitingProfileData: boolean) => (
    { type: 'AUTH_SET_IS_WAITING_PROFILE_DATA', isWaitingProfileData } as const
  ),
  setReset: () => ({ type: 'AUTH__SET_RESET' } as const),
  logout: () => ({ type: 'LOG_OUT' } as const)
}

export const signInWithPhoneNumber = (
  phoneNumber: string,
  setErrorMsg: (msg: string) => void,
  redirect?: () => void
): ThunkType =>
  async (dispatch) => {
    dispatch(actions.setIsLoading(true))
    const handlePopstate = () => {
      dispatch(actions.setIsLoading(false))
      window.removeEventListener('popstate', handlePopstate)
    }
    window.addEventListener('popstate', handlePopstate)
    signInWithPhoneNumberFirebase(firebaseAuth, phoneNumber, RecaptchaSingleton.getInstance(phoneNumber))
      .then((confirmation) => {
        dispatch(actions.setConfirmation(confirmation))
        dispatch(actions.setIsLoading(false))
        setErrorMsg('')
        if (redirect) redirect()
      })
      .catch((err) => {
        dispatch(actions.setIsLoading(false))
        setErrorMsg(err.code)
      })
  }

export const confirmCode = (
  code: string,
  invitedBy?: string,
  redirectParam?: string,
  redirect?: (path: string) => void
): ThunkType => async (dispatch, getState) => {
  dispatch(actions.setIsLoading(true))

  const { auth: { confirmation } } = getState()

  confirmation?.confirm(code)
    .then(async () => {
      // TODO: unregistered user status fail code - 404
      // return null in 404 status, else call error modal
      const myProfile = await profileAPI.getMyProfile().catch(() => null)

      if (!myProfile) {
        dispatch(profileActions.setMyProfile(null))
        if (isDev) console.error('Auth confirmCode then setAuth(true)')
        dispatch(actions.setConfirmation(null))
        dispatch(actions.setAuth(true))
      } else {
        dispatch(actions.setIsWaitingProfileData(true))
        await dispatch(initProfile())
        dispatch(actionsApp.setIsInitialLogin(true))
        dispatch(actions.setIsWaitingProfileData(false))
        if (isDev) console.error('Auth confirmCode then setAuth(true)')
        dispatch(actions.setConfirmation(null))
        dispatch(actions.setAuth(true))
        const queryParams = getQueryParamsFromUrl(decodeURIComponent(redirectParam || ''))
        const sharedBy = redirectParam
          ? queryParams.shared_by
          : invitedBy

        if (sharedBy && queryParams[ROUTE_SEARCH_PARAMS.VACANCY_ID]) {
          if (redirect) dispatch(actionsFilter.setListingFilters({ filters: [MyListingsFilters.VACANCY] }))
          redirect?.(getUserVacancyDetails(
            sharedBy
          ))
        } else if (sharedBy && queryParams[ROUTE_SEARCH_PARAMS.SPECIALITY_ID]) {
          if (redirect) dispatch(actionsFilter.setListingFilters({ filters: [MyListingsFilters.SPECIALTY] }))
          redirect?.(getUserSpecialtyDetails(
            sharedBy
          ))
        } else {
          const redirectUrl = sharedBy
            ? getUserDetailsPath(myProfile.username, sharedBy, myProfile.uid)
            : ROUTES.EXPLORE
          redirect?.(redirectUrl)
        }
      }

      dispatch(actions.setIsLoading(false))
    })
    .catch((err) => {
      if (err?.status === 400) {
        if (isDev) console.error('Auth confirmCode catch setAuth(true)')
        dispatch(actions.setAuth(true))
      }
      dispatch(actions.setIsFailedConfirmationCode(true))
      dispatch(actions.setIsLoading(false))
    })
}

export const getOnboardingProfile = (profileData: any, onFinish?: () => void): ThunkType =>
  async (dispatch) => {
    const { token: fcmToken, isSWActive } = await getTokenFcm()
    if (fcmToken) {
      dispatch(profileActions.setIsActiveFcm(true))
    }

    dispatch(commonActions.setIsSWActivated(isSWActive))

    dispatch(actions.setIsLoading(true))
    await profileAPIV2.afterSignup(profileData)
      .then(async ({ profile }) => {
        dispatch(actionsApp.setIsInitialLogin(true))
        dispatch(actionsHome.addBulkMemoizedUsers(profile.contacts))
        dispatch(profileActions.setMyProfile({ ...profile }))
        await getHomeCardsOptimized(profile.uid, dispatch)
        dispatch(initConversations())
        onFinish?.()
      })
      .catch(() => {
        dispatch(profileActions.setMyProfile(null))
        return null
      }).finally(() => {
        dispatch(actions.setIsLoading(false))
      })
  }

export const signOut = (redirect: () => void, forgetDevice = true): ThunkType =>
  async (dispatch, _getState, firebase) => {
    dispatch(actions.setIsLoading(true))
    const deviceId = LocalStorageService.getItem('deviceId')
    if (forgetDevice && deviceId) {
      await profileAPI.forgetDevice(deviceId).catch(() => false)
    }

    navigator.serviceWorker.getRegistrations().then((registrations) => {
      registrations.forEach((registration) => registration.unregister())
    })

    firebase.signOut()
      .then(() => {
        dispatch(actions.logout())
        LocalStorageService.clear()
        dispatch(profileActions.setMyProfile(null))
        dispatch(actions.setIsLoading(false))
        if (isDev) console.error('Auth signOut then setAuth(false)')
        dispatch(actions.setAuth(false))
        dispatch(commonActions.setInitialized(true))
        redirect()
      })
      .catch(() => {
        if (isDev) console.error('Auth signOut catch setAuth(false)')
        dispatch(actions.setIsLoading(false))
      })
  }

export const clearAuthState = (): ThunkType =>
  async (dispatch, _getState, firebase) => {
    firebase.signOut()
      .then(() => {
        dispatch(actions.logout())
        LocalStorageService.clear()
      })
      .catch((err) => err)
      .finally(() => {
        dispatch(commonActions.setInitialized(true))
        if (isDev) console.error('Auth clearAuthState finally setAuth(false)')
        dispatch(actions.setAuth(false))
      })
  }

export const deleteMyUser = (uid: string): ThunkType => async (dispatch) => {
  const deviceId = LocalStorageService.getItem('deviceId')
  if (deviceId) {
    await profileAPI.forgetDevice(deviceId).catch(() => false)
  }
  await usersAPI.deleteUser(uid).catch((err) => {
    const { status, error: errorMsg } = err as { status: number; error: string }
    dispatch(actionsNotifications.addErrorMsg({
      type: ErrorModalTypes.DEFAULT,
      description: errorMsg,
      status: status || 500
    }))
  })
  navigator.serviceWorker.getRegistrations().then((registrations) => {
    registrations.forEach((registration) => registration.unregister())
  })
  dispatch(actions.logout())
  LocalStorageService.clear()
  if (isDev) console.error('Auth deleteMyUser setAuth(false)')
  dispatch(actions.setAuth(false))
  dispatch(commonActions.setInitialized(true))
}

export const getAllSpecialties = (): ThunkType<string[]> => async (dispatch) => {
  try {
    const specialties = await profileAPI.getSpecialties<{ [key: string]: string[] }>()
    const result = Object
      .values(specialties)
      .flatMap((specialties) => specialties.filter(Boolean))
    dispatch(actions.setSpecialties(result))
    return result
  } catch (error) {
    return []
  }
}
