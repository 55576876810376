import translationsFallback from 'common/translationsFallback.json'
import envConfig from 'config'
import { ACTION_TYPES } from 'features/Translations/actions'
import type { ActionTypes, TranslationsType } from 'features/Translations/types'

const parser = (dataToParse: TranslationsType) => {
  return ({
    inviteViaLink: {
      inviteViaLink: dataToParse?.contacts_invite_sheet_link,
      copyLink: dataToParse?.contact_invite_link_copy,
      copyLinkShort: dataToParse?.contact_invite_link_copy_short,
      copyToClipBoard: dataToParse?.contacts_invite_link_copied,
      copyToClipBoardShort: dataToParse?.contacts_invite_link_copied_short,
      profileShareLinkCopiedTitle: dataToParse?.profile_share_link_copied_title
    },
    notifications: {
      photoMessage: dataToParse?.notification_photo_message,
      voiceMessage: dataToParse?.notification_voice_message,
      jobNotificationMessage: dataToParse?.local_job_notification_alert_body
    },
    myNetwork: {
      title: dataToParse?.contacts_text_title,
      subtitle: dataToParse?.contacts_text_subtitle,
      search: dataToParse?.contacts_search_placeholder,
      sort: dataToParse?.contacts_profile_button_sort,
      sortingFirstTrusted: dataToParse?.contacts_text_sorting_first_trusted,
      sortingFirstUnTrusted: dataToParse?.contacts_text_sorting_first_untrusted,
      trustedBy: dataToParse?.profile_table_section_trusted_by,
      trustingTitle: dataToParse?.network_modal_trusting_title,
      myProfileContacts: dataToParse?.my_profile_contacts,
      trustedTitle: dataToParse?.network_modal_trusted_title,
      searchTrustedUserPlaceholder: dataToParse?.trusted_user_search_placeholder,
      searchOtherTrustedUserPlaceholder: dataToParse?.other_trusted_user_search_placeholder,
      shareProfile: dataToParse?.contacts_profile_button_share_link,
      others: dataToParse?.contacts_text_others_section,
      firstLevel: dataToParse?.contacts_text_first_section,
      secondLevel: dataToParse?.contacts_text_second_section,
      selectContactText: dataToParse?.network_text_select_contact,
      contactsEmptyTitle: dataToParse?.contacts_empty_title,
      contactsEmptyDescription: dataToParse?.contacts_empty_description,
      networkFiltersFirst: dataToParse?.network_filters_first,
      networkFiltersSecond: dataToParse?.network_filters_second,
      networkFiltersAll: dataToParse?.network_filters_all,
      networkModalFiltersTrusted: dataToParse?.network_modal_filters_trusted,
      networkModalFiltersTrusting: dataToParse?.network_modal_filters_trusting,
      networkModalAddButton: dataToParse?.trust_modal_add_button
    },
    profile: {
      title: dataToParse?.profile_settings_sheet_account_text_title,
      links: dataToParse?.profile_edit_profile_fields_text_social_media,
      vacancies: dataToParse?.contacts_profile_segmented_control_vacancies_title,
      job: dataToParse?.profile_edit_profile_fields_text_job,
      industry: dataToParse?.profile_edit_profile_fields_text_industry,
      company: dataToParse?.profile_edit_profile_fields_text_company,
      aboutMe: dataToParse?.profile_table_section_name_about_me,
      edit: dataToParse?.profile_edit_profile_fields,
      linksLimit: dataToParse?.profile_edit_profile_fields_text_links_limit,
      url: dataToParse?.profile_edit_profile_fields_text_social_media_url,
      requiredLinkNameErrorMsg: dataToParse?.profile_edit_links_required_link_name,
      requiredLinkUrlErrorMsg: dataToParse?.profile_edit_links_required_link_url,
      correctLinkErrorMsg: dataToParse?.profile_edit_links_correct_link_error_message,
      duplicateLinksErrorMsg: dataToParse?.profile_edit_links_duplicate_error_message,
      linkName: dataToParse?.profile_edit_profile_fields_text_social_media_link_name,
      displayName: dataToParse?.profile_edit_profile_fields_text_display_name,
      jobTitle: dataToParse?.job_title,
      prepositionAt: dataToParse?.profile_view_text_at,
      tags: dataToParse?.profile_edit_profile_fields_text_tags,
      location: dataToParse?.company_info_location,
      companyAddressOrLocation: dataToParse?.profile_edit_profile_fields_text_company_address,
      editTagsSubtitle: dataToParse?.profile_edit_text_tags_subtitle,
      maxCountTrusts: envConfig.isProd
        ? dataToParse?.trust_max_limit_count_prod
        : dataToParse?.trust_max_limit_count_dev,
      max: dataToParse?.onboarding_text_max,
      tagsLimitErrorMsg: dataToParse?.profile_edit_text_tags_limit_error,
      description: dataToParse?.profile_edit_fields_description,
      workingPolicy: dataToParse?.contacts_profile_segmented_control_working_policy,
      salary: dataToParse?.contacts_profile_segmented_control_salary,
      aboutJob: dataToParse?.contacts_profile_segmented_control_about_job,
      loadButton: dataToParse?.profile_photo_load,
      removeButton: dataToParse?.profile_photo_remove,
      editButton: dataToParse?.profile_photo_edit,
      backButton: dataToParse?.profile_photo_back,
      saveButton: dataToParse?.profile_photo_save,
      notSpecifiedSalary: dataToParse?.choose_job_not_specified,
      incorrectField: dataToParse?.onboarding_incorrect_field,
      openTo: dataToParse?.profile_table_section_open_to,
      hiring: dataToParse?.profile_table_section_hiring,
      save: dataToParse?.button_save,
      cancel: dataToParse?.profile_settings_actions_delete_profile_alert_cancel,
      vacancy: dataToParse?.contacts_profile_segmented_control_vacancy,
      textClosedVacancy: dataToParse?.vacancy_closed_title,
      vacancyLimitMessage: dataToParse?.vacancy_limit_message,
      specialtyLimitMessage: dataToParse?.open_to_limit_message,
      maxVacanciesCount: dataToParse?.max_vacancies_count,
      maxResumesCount: dataToParse?.max_resumes_count,
      jobHistory: dataToParse?.profile_job_history,
      emptyAboutTab: dataToParse?.about_other_profile_empty_state,
      companyInfo: dataToParse?.company_info_company,
      editProfileTitle: dataToParse?.profile_edit_profile_fields_text_title,
      tagsSubtitle: dataToParse?.tags_text_field_title,
      trustLimit: dataToParse?.trust_limit_title,
      editTrusts: dataToParse?.edit_trusts,
      trustLimitMessage: dataToParse?.trust_limit_exceeded,
      buttonTextOk: dataToParse?.error_alert_button_ok,
      suggestedSectionTitle: dataToParse?.contacts_text_suggested_section,
      emptyTrustingHelperTitle: dataToParse?.contacts_text_empty_trusting_section_title,
      emptyTrustingHelperSubtitle: dataToParse?.contacts_text_empty_trusting_section_subtitle,
      profileTitle: dataToParse?.profile_text_title,
      helperTextLimit: dataToParse?.profile_edit_text_limit_error,
      addNew: dataToParse?.profile_edit_add_new_vacancy,
      helperJobTitle: dataToParse?.empty_job_title_error,
      helperCurrency: dataToParse?.vacancies_add_vacancy_error_message_currency,
      locationTitle: dataToParse?.profile_open_to_location,
      jobTypeTitle: dataToParse?.profile_publish_job_type_of_job,
      jobTypeErrorText: dataToParse?.profile_publish_work_type_error_text,
      jobLocationTitle: dataToParse?.profile_table_section_name_company_address,
      jobLocationTypeTitle: dataToParse?.profile_publish_job_location_type,
      jobAudienceTitle: dataToParse?.profile_open_to_job_audience,
      currency: dataToParse?.profile_add_vacancies_currency,
      salaryFrom: dataToParse?.profile_add_vacancies_from,
      salaryTo: dataToParse?.profile_add_vacancies_to,
      locationTypeError: dataToParse?.profile_publish_location_typ_error_text,
      locationError: dataToParse?.profile_publish_locations_error_text,
      rateError: dataToParse?.profile_publish_rate_error_text,
      salaryPartiallyFilledError: dataToParse?.profile_publish_partially_error_text,
      errorMessageSalaryTo: dataToParse?.vacancies_error_message_salary_to,
      errorMessageSalaryFrom: dataToParse?.vacancies_error_message_salary_from,
      buttonTextPublish: dataToParse?.profile_publish_job_button_title,
      buttonTextCancel: dataToParse?.profile_cancel_job_button_title,
      rate: dataToParse?.vacancies_add_vacancy_rate,
      textAnd: dataToParse?.phone_enter_text_legal_and,
      alertMsg: dataToParse?.vacancy_archive_alert_message,
      deleteVacancyMessage: dataToParse?.vacancy_delete_alert_message,
      deleteVacancyTitle: dataToParse?.vacancy_delete_alert_title,
      closeButton: dataToParse?.profile_button_close,
      deleteButton: dataToParse?.button_delete,
      subtitleVacancyDetails: dataToParse?.profile_open_to_details,
      wrongSalaryRange: dataToParse?.vacancies_error_message_wrong_salary_range,
      specialityAboutMe: dataToParse?.vacancy_description_open_to_about_me,
      vacancyAboutErrorMessage: dataToParse?.vacancies_error_message_about_me,
      specialtyAboutErrorMessage: dataToParse?.resume_error_message_about_me,
      closeHiring: dataToParse?.vacancy_archive_alert_title,
      vacanciesEmptyDescription: dataToParse?.vacancies_empty_description,
      deleteSpecialityTitle: dataToParse?.resume_delete_alert_title,
      deleteSpecialityMessage: dataToParse?.resume_delete_alert_message,
      createVacancyEmptyUrlTitle: dataToParse?.create_vacancy_empty_url_title,
      createResumeEmptyUrlTitle: dataToParse?.create_resume_empty_url_title,
      createResumeFilledUrlTitle: dataToParse?.create_resume_filled_url_title,
      createVacancyFilledUrlTitle: dataToParse?.create_vacancy_filled_url_title,
      vacancyDetailsAdditionalSettings: dataToParse?.vacancy_details_additional_settings,
      resumeDetailsAdditionalSettings: dataToParse?.resume_details_additional_settings
    },
    myProfile: {
      hiringTabEmptyState: dataToParse?.my_profile_hiring_tab_empty_state,
      openToTabEmptyState: dataToParse?.my_profile_open_to_tab_empty_state,
      tableSectionDreamJob: dataToParse?.my_profile_table_section_dream_job,
      dreamJobBottomSheet: dataToParse?.my_profile_dream_job_bottom_sheet,
      openToBottomSheet: dataToParse?.my_profile_open_to_bottom_sheet,
      trustedText: dataToParse?.my_profile_trusted_text,
      websitesPlaceholder: dataToParse?.my_profile_websites_placeholder,
      tagsPlaceholder: dataToParse?.my_profile_tags_placeholder,
      aboutPlaceholder: dataToParse?.my_profile_about_placeholder,
      segmentedControlHiring: dataToParse?.my_profile_segmented_control_hiring,
      segmentedControlOpenTo: dataToParse?.my_profile_segmented_control_open_to,
      segmentedControlAbout: dataToParse?.my_profile_segmented_control_about,
      profileLinkCopied: dataToParse?.profile_link_copied,
      profileUrl: dataToParse?.profile_table_section_url,
      contactInfo: dataToParse?.profile_table_section_contact_info,
      resumeDeleteButton: dataToParse?.resume_delete_alert_button,
      vacancyDeleteButton: dataToParse?.vacancy_delete_alert_button,
      resumeDeleteCancelButton: dataToParse?.resume_delete_cancel_button,
      vacancyDeleteCancelButton: dataToParse?.vacancy_delete_cancel_button
    },
    otherProfile: {
      chatButton: dataToParse?.other_profile_chat_button,
      introduceButton: dataToParse?.other_profile_introduce_button,
      contactBottomSheetUntrust: dataToParse?.other_contact_bottom_sheet_untrust,
      contactBottomSheetTrust: dataToParse?.other_contact_bottom_sheet_trust,
      contactBottomSheetShare: dataToParse?.other_contact_bottom_sheet_share,
      contactBottomSheetAddContact: dataToParse?.other_contact_bottom_sheet_add_contact,
      tableSectionDreamJob: dataToParse?.other_profile_table_section_dream_job,
      hiringTabEmptyState: dataToParse?.other_profile_hiring_tab_empty_state,
      openToTabEmptyState: dataToParse?.other_profile_open_to_tab_empty_state,
      segmentedControlHiring: dataToParse?.other_profile_segmented_control_hiring,
      segmentedControlOpenTo: dataToParse?.other_profile_segmented_control_open_to,
      segmentedControlAbout: dataToParse?.other_profile_segmented_control_about,
      profileLinkCopied: dataToParse?.profile_link_copied,
      profileUrl: dataToParse?.profile_table_section_url,
      trustingModalEmptyStateTitle: dataToParse?.trusting_modal_empty_state_title,
      trustingModalEmptyStateDescription: dataToParse?.trusting_modal_empty_state_description,
      trustingModalTitle: dataToParse?.trusting_modal_title,
      trustListSearchPlaceholder: dataToParse?.trust_list_search_placeholder,
      publicProfileSignupModalProfile: dataToParse?.public_profile_signup_modal_profile,
      publicProfileSignupModalListings: dataToParse?.public_profile_signup_modal_listings
    },
    introduce: {
      seeChatButton: dataToParse?.introduce_alert_see_chat_button,
      okButton: dataToParse?.introduce_alert_ok,
      exploreCreatedOkButton: dataToParse?.explore_created_ok,
      titleSuccessModal: dataToParse?.introduce_alert_title,
      accountDeletedModalTitle: dataToParse?.introduce_account_deleted_modal_title,
      accountDeletedModalDescription: dataToParse?.introduce_account_deleted_modal_description,
      recommendedBy: dataToParse?.contacts_text_recommended_by,
      description: dataToParse?.introduce_message_description,
      emptyMessageError: dataToParse?.introduce_empty_message_error,
      messagePlaceholder: dataToParse?.introduce_message_placeholder,
      sendButton: dataToParse?.introduce_message_send_button,
      alreadyIntroduced: dataToParse?.introduce_alert_users_already_introduced,
      chooseSomeoneElse: dataToParse?.introduce_error_choose_someone_else,
      modalTitle: dataToParse?.introduce_modal_title,
      chooseSomeoneToIntroduce: dataToParse?.please_choose_someone_to_introduce,
      chooseButton: dataToParse?.introduce_choose_button,
      firstModalTitle: dataToParse?.introduce_title,
      messageTitle: dataToParse?.introduce_message_title,
      introEmptyTitle: dataToParse?.intro_empty_title,
      introEmptyDescription: dataToParse?.intro_empty_description,
      searchIntroUserPlaceholder: dataToParse?.search_intro_user_placeholder,
      introductionBy: dataToParse?.introduction_by
    },
    errorMsgs: {
      emptySearchStateTitle: dataToParse?.empty_search_state_title,
      emptySearchStateSubTitle: dataToParse?.empty_search_state_sub_title,
      emptyNetworkSearchStateSubTitle: dataToParse?.empty_network_search_state_sub_title,
      locationTypeError: dataToParse?.profile_publish_location_typ_error_text,
      locationError: dataToParse?.profile_publish_locations_error_text,
      rateError: dataToParse?.profile_publish_rate_error_text,
      helperJobTitle: dataToParse?.empty_job_title_error,
      helperTextRepeatTag: dataToParse?.profile_edit_text_exist_tag_error,
      errorMsgCurrency: dataToParse?.vacancies_add_vacancy_error_message_currency,
      errorMsgLocationsLimit: dataToParse?.vacancies_error_message_locations_max_count,
      errorMsgDescriptionLimit: dataToParse?.vacancies_error_message_description_max_count,
      tagsLimitErrorMsg: dataToParse?.profile_edit_text_tags_limit_error,
      helperTextLimit: dataToParse?.profile_edit_text_limit_error,
      helperTextLimitMin: dataToParse?.min_text_limit_error,
      locationExistError: dataToParse?.vacancies_error_message_exist_location_error,
      wrongLocation: dataToParse?.vacancies_error_message_wrong_location,
      vacanciesRequiredLocation: dataToParse?.vacancies_error_message_required_location
    },
    chatTexts: {
      title: dataToParse?.bottom_navigation_messages,
      contacts: dataToParse?.bottom_navigation_contacts,
      listings: dataToParse?.bottom_navigation_my_profile,
      messageSuccessModal: dataToParse?.introduce_alert_message,
      chatName: dataToParse?.messages_text_introduce_chat_name,
      messageSeenBy: dataToParse?.messages_seen_by,
      offline: dataToParse?.chat_text_user_is_offline,
      online: dataToParse?.chat_text_user_is_online,
      placeholder: dataToParse?.chat_text_placeholder,
      deletedAccount: dataToParse?.chat_text_deleted_account,
      chatTextMe: dataToParse?.chat_text_me,
      selectChat: dataToParse?.chat_text_select_contact,
      messagesEmptyTitle: dataToParse?.messages_empty_title,
      messagesEmptyDescription: dataToParse?.messages_empty_description,
      messagesFileUpload: dataToParse?.messages_file_upload,
      matchedMessages: dataToParse?.messages_search_matched_messages,
      startedVacancyChat: dataToParse?.conversation_started_vacancy,
      startedSpecialtyChat: dataToParse?.conversation_started_specialty,
      microphonePermissionDisabled: dataToParse?.chat_text_microphone_permission_disabled,
      audioRecordingSecondsLimit: dataToParse?.audio_recording_seconds_limit,
      voiceMessage: dataToParse?.chat_audio_cell_title,
      chatNoMessageSubtitle: dataToParse?.chat_text_no_message_subtitle_for_introduce,
      chatNoMessageTitle: dataToParse?.chat_text_no_message_title,
      chatTextAnd: dataToParse?.chat_text_and,
      chatMenuTextCopy: dataToParse?.chat_action_menu_copy,
      chatMenuTextDelete: dataToParse?.chat_action_menu_delete,
      chatMenuTextReply: dataToParse?.chat_action_menu_reply,
      chatMenuTextSave: dataToParse?.chat_action_menu_save,
      chatSearchPlaceholder: dataToParse?.chat_search_placeholder
    },
    onboarding: {
      enterYourNumber: dataToParse?.phone_enter_text_enter_your_number,
      phoneInputPlaceholder: dataToParse?.phone_input_placeholder,
      resendCodeWaiting: dataToParse?.phone_verification_button_resend_code_waiting,
      yourName: dataToParse?.authentication_name_enter_text_first_name,
      yourLastName: dataToParse?.authentication_name_enter_text_last_name,
      yourUserName: dataToParse?.authentication_name_enter_text_user_name,
      helperFirstName: dataToParse?.authentication_name_enter_text_first_name_error,
      helperLastName: dataToParse?.authentication_name_enter_text_last_name_error,
      discardChangesSubTitle: dataToParse?.profile_edit_text_discard_changes_subtitle,
      discardChangesTitle: dataToParse?.profile_edit_text_discard_changes_title,
      discardButtonText: dataToParse?.voice_recording_warning_modal_discard,
      cancelButtonText: dataToParse?.button_cancel,
      landingTitle: dataToParse?.landing_title,
      landingPhoneNumber: dataToParse?.landing_your_phone_number,
      landingLogIn: dataToParse?.landing_log_in,
      landingSignUp: dataToParse?.landing_sign_up,
      authLogIn: dataToParse?.authentication_log_in,
      authSignUp: dataToParse?.authentication_sign_up,
      textLegalAgree: dataToParse?.phone_enter_text_legal_agree,
      textLegalTerms: dataToParse?.phone_enter_text_legal_terms,
      resendCode: dataToParse?.phone_verification_button_resend_code,
      authenticationUrlTerms: dataToParse?.authentication_phone_enter_url_terms,
      privacyPolicyTitle: dataToParse?.profile_settings_sheet_actions_privacy_policy,
      pageDoesNotExistTitle: dataToParse?.page_does_not_exist_title,
      pageDoesNotExistDesc: dataToParse?.page_does_not_exist_desc,
      pageDoesNotExistButton: dataToParse?.page_does_not_exist_button,
      authenticationUrlPrivacy: dataToParse?.authentication_phone_enter_url_privacy,
      textLegalPrivacy: dataToParse?.phone_enter_text_legal_privacy,
      textLegalAnd: dataToParse?.phone_enter_text_legal_and,
      buttonContinue: dataToParse?.phone_enter_button_continue,
      verificationEnterCodeFor: dataToParse?.phone_verification_text_enter_code,
      dreamJobTitle: dataToParse?.authentication_dream_jobs_text_title,
      titleFirstTime: dataToParse?.phone_enter_title_first_time,
      displayNameTextTitle: dataToParse?.authentication_display_name_text_title,
      nextButton: dataToParse?.multichoice_button_next,
      startButton: dataToParse?.authentication_greetings_screen_button_start,
      backButton: dataToParse?.navigation_bar_button_back,
      popularChoice: dataToParse?.onboarding_popular_choice,
      dreamJobSubTitle: dataToParse?.authentication_dream_jobs_text_subtitle,
      photoTitle: dataToParse?.authentication_upload_photo_header_title,
      photoSubtitle: dataToParse?.authentication_upload_photo_header_subtitle,
      dreamJobDescription: dataToParse?.authentication_dream_jobs_text_description,
      displayNameSubtitle: dataToParse?.authentication_name_enter_text_subtitle,
      displayNameDescription: dataToParse?.authentication_name_enter_text_name,
      yourChoice: dataToParse?.onboarding_your_choice,
      invalidCode: dataToParse?.phone_verification_hud_incorrect_code,
      jobTextSubtitle: dataToParse?.choose_job_text_subtitle,
      employerSubtitleStages: dataToParse?.multichoice_stages_investor_subtitle,
      employeeSubtitleIndustries: dataToParse?.multichoice_industries_investor_subtitle,
      employerSubtitleIndustries: dataToParse?.multichoice_industries_founder_subtitle,
      jobTextTitle: dataToParse?.choose_job_text_title,
      employeeSelectSpeciality: dataToParse?.choose_job_text_speciality,
      selectLocation: dataToParse?.choose_job_text_location,
      switchButtonOnSite: dataToParse?.choose_job_switch_button_on_site,
      switchButtonRemote: dataToParse?.choose_job_switch_button_remote,
      switchButtonsTitle: dataToParse?.choose_job_switch_buttons_title,
      dreamJobKeywords: dataToParse?.profile_add_vacancies_job_titles_keywords,
      tagsTitle: dataToParse?.profile_table_section_name_tags,
      tagsDescription: dataToParse?.tags_editor_description,
      tagsSubtitle: dataToParse?.tags_text_field_title,
      vacancyTitle: dataToParse?.profile_table_section_vacancy,
      vacancyDescription: dataToParse?.profile_add_vacancies_description,
      salarySubtitle: dataToParse?.salary_subtitle,
      salaryTitle: dataToParse?.salary_title,
      currency: dataToParse?.profile_add_vacancies_currency,
      optional: dataToParse?.profile_add_vacancies_optional,
      salaryFrom: dataToParse?.profile_add_vacancies_from,
      salaryTo: dataToParse?.profile_add_vacancies_to,
      companyInfoWebsite: dataToParse?.company_info_website,
      vacancyPeyPeriodDay: dataToParse?.vacancies_add_vacancies_pay_period_day,
      vacancyPeyPeriodHour: dataToParse?.vacancies_add_vacancies_pay_period_hour,
      vacancyPeyPeriodMonth: dataToParse?.vacancies_add_vacancies_pay_period_month,
      vacancyPeyPeriodYear: dataToParse?.vacancies_add_vacancies_pay_period_year,
      vacancyPeyPeriodWeek: dataToParse?.vacancies_add_vacancies_pay_period_week,
      companyInfo: dataToParse?.company_info_company,
      alreadySwitchButton: dataToParse?.authentication_dream_job_switch_button_already,
      vacancyFixedPrice: dataToParse?.vacancies_add_vacancies_pay_period_piece,
      placeholderVerificationCode: dataToParse?.phone_verification_code_message,
      msgForLimitExceeded: dataToParse?.phone_invalid_attempts_limit,
      msgForInvalidNumber: dataToParse?.phone_invalid_short_number,
      footerCopyright: dataToParse?.footer_uplift_copyright,
      msgForInvalidPhoneNumber: dataToParse?.auth_invalid_phone_number,
      msgForNoInternetAccess: dataToParse?.auth_no_internet_access,
      generalErrorMsg: dataToParse?.auth_general_error_message,
      nameTextTitle: dataToParse?.authentication_name_enter_text_title,
      nameTextSubTitle: dataToParse?.authentication_name_enter_text_subtitle,
      jobTextDescription: dataToParse?.current_job_title
    },
    vacancies: {
      edited: dataToParse?.vacancies_edited,
      fullTime: dataToParse?.vacancies_job_type_full_time,
      partTime: dataToParse?.vacancies_job_type_part_time,
      contractor: dataToParse?.vacancies_job_type_contractor,
      smallJobs: dataToParse?.vacancies_job_type_small_jobs,
      internship: dataToParse?.vacancies_job_type_internship,
      volunteer: dataToParse?.vacancies_job_type_volunteer,
      advisor: dataToParse?.vacancies_job_type_advisor,
      boardDirectors: dataToParse?.vacancies_job_type_board_of_directors,
      projectBased: dataToParse?.vacancies_job_type_project_based,
      startupBusinessPartner: dataToParse?.vacancies_job_type_startup_businness_partner,
      payPeriodPiece: dataToParse?.vacancies_add_vacancies_pay_period_piece,
      onlyTrusts: dataToParse?.vacancies_job_audience_only_trusts,
      myNetwork: dataToParse?.vacancies_job_audience_my_network,
      inPerson: dataToParse?.vacancies_location_type_in_person,
      remote: dataToParse?.choose_job_switch_button_remote,
      hybrid: dataToParse?.vacancies_location_type_hybrid,
      strangers: dataToParse?.vacancies_job_audience_strangers,
      onlyMe: dataToParse?.vacancies_job_audience_only_me,
      openToEdited: dataToParse?.profile_open_to_edited,
      openToPublished: dataToParse?.profile_open_to_published,
      vacancyDetailOpenToWork: dataToParse?.vacancy_detail_open_to_work,
      vacancyDetailHiringBy: dataToParse?.vacancy_detail_hiring_by,
      profileTagsPlaceholder: dataToParse?.profile_tags_placeholder_text
    },
    home: {
      cardNotFoundTitle: dataToParse?.home_card_not_found_title,
      homeCardNotFoundGoToHome: dataToParse?.home_card_not_found_go_to_home,
      title: dataToParse?.bottom_navigation_swiper,
      homeTitle: dataToParse?.home_recommendations_title,
      homeSearchPlaceholder: dataToParse?.home_search_placeholder,
      startChatButton: dataToParse?.contacts_profile_button_open_chat,
      skipButton: dataToParse?.contacts_profile_button_skip,
      filtersTitle: dataToParse?.home_filters_title,
      iosApplication: dataToParse?.ios_application,
      androidApplication: dataToParse?.android_application,
      homeDescription: dataToParse?.home_no_matches_description,
      exploreNoMatchesTitle: dataToParse?.explore_no_matches_title,
      exploreNoMatchesDescription: dataToParse?.explore_no_matches_description,
      trustedBy: dataToParse?.profile_table_section_trusted_by,
      others: dataToParse?.swiper_text_others,
      other: dataToParse?.swiper_text_other,
      trustedPath: dataToParse?.profile_table_section_trust_path,
      explorePageTitle: dataToParse?.explore_page_title,
      explorePageFrom: dataToParse?.explore_page_from,
      explorePageStartChat: dataToParse?.explore_page_start_a_chat,
      explorePageArchiveJob: dataToParse?.explore_page_archive_job,
      explorePageEditJob: dataToParse?.explore_page_edit_job,
      buttonGoToGroupChat: dataToParse?.button_go_to_group_chat,
      explorePageChat: dataToParse?.explore_page_chat,
      explorePageIntroduce: dataToParse?.explore_page_introduce,
      exploreLinkTitle: dataToParse?.explore_link_title,
      exploreLinkCopyMessage: dataToParse?.explore_link_copy_message,
      exploreInviteViaLink: dataToParse?.explore_invite_via_link,
      exploreCreateOpenTo: dataToParse?.explore_create_open_to,
      cardPopupActionsShare: dataToParse?.card_popup_actions_share,
      cardPopupActionsHide: dataToParse?.card_popup_actions_hide,
      cardPopupActionsEdit: dataToParse?.job_card_bottom_sheet_edit,
      cardPopupActionsArchive: dataToParse?.job_card_bottom_sheet_archive,
      cardPopupActionsDelete: dataToParse?.job_card_bottom_sheet_delete,
      cardPopupActionsShareTitle: dataToParse?.card_popup_actions_share_title,
      cardPopupActionsShareCopyText: dataToParse?.card_popup_actions_share_copy_text,
      cardPopupActionsShareCopied: dataToParse?.card_popup_actions_share_copied,
      cardPopupActionsShareFacebookTitle: dataToParse?.card_popup_actions_share_facebook_title,
      cardPopupActionsShareLinkedInTitle: dataToParse?.card_popup_actions_share_linked_in_title,
      cardPopupActionsShareWhatsAppTitle: dataToParse?.card_popup_actions_share_whats_app_title,
      cardPopupActionsShareGmailTitle: dataToParse?.card_popup_actions_share_gmail_title,
      cardPopupActionsShareGmailBody: dataToParse?.card_popup_actions_share_gmail_body,
      cardPopupActionsShareWhatsAppBody: dataToParse?.card_popup_actions_share_whats_app_body,
      exploreCreateHiring: dataToParse?.explore_create_hiring,
      exploreShowMore: dataToParse?.explore_page_show_more,
      exploreShowFewer: dataToParse?.explore_page_show_fewer,
      exploreCreatedTitle: dataToParse?.explore_created_title,
      exploreHiringCreatedAlertMessage: dataToParse?.explore_hiring_created_message,
      exploreOpenToCreatedAlertMessage: dataToParse?.explore_open_to_created_message,
      homeMatchesHiring: dataToParse?.home_matches_hiring,
      homeMatchesOpenTo: dataToParse?.home_matches_open_to,
      homeOpenChatModalOk: dataToParse?.home_open_chat_modal_ok,
      homeOpenChatModalCancel: dataToParse?.home_open_chat_modal_cancel,
      homeOpenChatModalTitle: dataToParse?.home_open_chat_modal_title,
      homeOpenChatModalDescription: dataToParse?.home_open_chat_modal_description,
      homeButtonCreateJob: dataToParse?.home_button_create_job,
      homeFiltersOffering: dataToParse?.home_filters_offering,
      homeFiltersOpenTo: dataToParse?.home_filters_open_to,
      homeFiltersMyJobs: dataToParse?.home_filters_my_jobs
    },
    support: {
      title: dataToParse?.support_page_title,
      description: dataToParse?.support_page_description,
      inputName: dataToParse?.support_page_input_name,
      inputEmail: dataToParse?.support_page_input_email,
      inputSubject: dataToParse?.support_page_input_subject,
      inputMessage: dataToParse?.support_page_input_message,
      inputSubjectTechnicalSupport: dataToParse?.support_page_input_subject_option_techincal_support,
      inputSubjectEnterpriseSalse: dataToParse?.support_page_input_subject_option_enterprise_sales,
      inputSubjectReportUser: dataToParse?.support_page_input_subject_option_report_user,
      inputSubjectReportContent: dataToParse?.support_page_input_subject_option_report_content,
      inputSubjectOther: dataToParse?.support_page_input_subject_option_other,
      addAttachment: dataToParse?.support_page_add_attachment,
      attachmentSizeLimit: dataToParse?.support_page_attachment_size_limit,
      maxFileCountLimit: dataToParse?.support_page_max_file_count_limit,
      maxFilesSizeLimit: dataToParse?.support_page_max_files_size_limit,
      sendMessage: dataToParse?.support_page_send_message,
      yourRequestSendTitle: dataToParse?.support_page_your_request_send_title,
      yourRequestSendDescription: dataToParse?.support_page_your_request_send_description,
      sendAnotherMessage: dataToParse?.support_page_send_another_message,
      pleaseEnterName: dataToParse?.support_page_please_enter_name,
      pleaseEnterEmail: dataToParse?.support_page_please_enter_email,
      pleaseEnterValidEmail: dataToParse?.support_page_please_enter_valid_email,
      pleaseSelectSubject: dataToParse?.support_page_please_select_subject,
      pleaseEnterMessage: dataToParse?.support_page_please_enter_message
    },
    listing: {
      listingTitle: dataToParse?.listings_title,
      listingEmptyStateTitle: dataToParse?.empty_state_all_listings_title,
      listingFiltersAll: dataToParse?.listings_filter_all,
      listingFiltersOffering: dataToParse?.listings_filter_vacancy,
      listingFiltersOpenTo: dataToParse?.listings_filter_resume,
      listingEmptyAll: dataToParse?.empty_state_all_listings_description,
      listingEmptyOpenTo: dataToParse?.empty_state_open_to_listings_description,
      listingEmptyOffering: dataToParse?.empty_state_offering_listings_description
    },
    archive: {
      archiveEmptyStateTitle: dataToParse?.empty_state_archive_title
    },
    settings: {
      viewProfile: dataToParse?.profile_settings_actions_view_profile,
      contactSupport: dataToParse?.profile_settings_actions_contact_support,
      deleteProfile: dataToParse?.profile_settings_actions_delete_profile,
      signOut: dataToParse?.profile_settings_sheet_actions_sign_out,
      signOutAlertTitle: dataToParse?.profile_sign_out_alert_title,
      profileDeleteMessage: dataToParse?.profile_delete_message,
      profileDeleteAreYouSureMessage: dataToParse?.profile_delete_are_you_sure_message,
      shareProfile: dataToParse?.profile_settings_actions_share,
      cancelButtonText: dataToParse?.profile_settings_modal_actions_sign_out_cancel
    },
    errorModal: {
      jobDetailOutdatedInfo: dataToParse?.job_detail_error_outdated_info_message,
      errorModalContent: dataToParse?.profile_text_warning_message,
      errorMessageText: dataToParse?.error_alert_message_text,
      errorButton: dataToParse?.error_alert_button_reload,
      errorMessage: dataToParse?.error_alert_message_1,
      errorProfileDeletedTitle: dataToParse?.profile_text_not_found_title,
      errorProfileDeletedDesc: dataToParse?.profile_text_not_found_body,
      errorShareTokenExpiredTitle: dataToParse?.shared_link_token_expired,
      errorShareTokenExpiredDesc: dataToParse?.shared_link_cant_open_profile
    },
    publicData: {
      joinUs: dataToParse?.contacts_profile_text_join_us,
      joinUsModal: dataToParse?.contacts_profile_modal_text_join_us,
      signIn: dataToParse?.contacts_profile_text_sign_in,
      useAppSuggestion: dataToParse?.use_app_suggestion
    },
    appLinks: {
      googlePlay: dataToParse?.app_links_google_play,
      appStore: dataToParse?.app_links_app_store
    },
    editProfile: {
      setNewPhoto: dataToParse?.profile_edit_avatar,
      firstNameTitle: dataToParse?.profile_edit_first_name_title,
      lastNameTitle: dataToParse?.profile_edit_last_name_title,
      userNameTitle: dataToParse?.profile_edit_user_name_title,
      locationTitle: dataToParse?.profile_edit_location_title,
      companyTitle: dataToParse?.profile_edit_company_title,
      currentJobTitle: dataToParse?.profile_edit_current_job_title,
      saveButton: dataToParse?.profile_edit_save_button,
      emptyUserNameError: dataToParse?.profile_edit_empty_user_name_error,
      userNameRegexError: dataToParse?.profile_edit_user_name_regex_error,
      usernameAlreadyExistsError: dataToParse?.profile_edit_username_already_exist_error,
      editGeneralInfo: dataToParse?.profile_edit_general_title,
      addWebsite: dataToParse?.my_profile_websites_placeholder,
      emailFieldError: dataToParse?.profile_edit_valid_email_error,
      emailLabel: dataToParse?.profile_edit_email,
      emptyEmailError: dataToParse?.profile_edit_empty_email_error,
      editBtn: dataToParse?.profile_edit_button
    }
  })
}

const initialState = {
  isLoading: true,
  data: parser(translationsFallback)
}

export const TranslationsReducer = (state = initialState, action: ActionTypes): typeof initialState => {
  switch (action.type) {
    case ACTION_TYPES.SET_DATA: return { ...state, data: parser(action.data), isLoading: false }
    case ACTION_TYPES.SET_IS_LOADING: return { ...state, isLoading: action.isLoading }
    default: return state
  }
}
