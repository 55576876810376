import React, {
  Fragment, useCallback, useEffect, useRef
} from 'react'
import {
  Controller, FormProvider, useForm, UseFormGetValues, UseFormReset
} from 'react-hook-form'
import { useSelector } from 'react-redux'
import { LOCALISE_SEPARATORS, VACANCY_MODAL_WIDTH } from 'common/constants'
import { FIELDS } from 'common/enums'
import { CloseIcon } from 'common/icons_V2/CloseIcon'
import { Typography, TypographyVariants } from 'common/typography'
import { FormExpandableInput, withExpandableInputProps } from 'features/FormExpandableInput_V2'
import { FormInput } from 'features/FormInput_V2'
import { Modal, ModalFooter } from 'features/Modal_V2'
import {
  IVacancySpecialityForm,
  useLocationValidation,
  useVacancyForm,
  VacancyFieldsConfig,
  validateSalary
} from 'features/MyProfile/components/VacancySpeciality_V2/validator'
import { selectProfileTranslations } from 'features/Translations/selectors'
import { withDiscardModal } from 'hocs/withDiscardModal'
import styles from './styles.module.sass'

const ExpandableInput = withExpandableInputProps(FormExpandableInput)

type FieldsRefs = {
  [key in FIELDS]: HTMLDivElement | null
}

enum INPUT_TYPES {
  INPUT = 'input',
  EXPENDABLE_INPUT = 'expandableInput',
  TEXTAREA = 'textarea',
  CHECKBOX = 'checkbox',
}

interface IVacancySpecialityModal {
  title: string,
  initialInputValues: IVacancySpecialityForm,
  sectionValues: { [key in FIELDS]?: { [key: string]: string } };
  isOnEditing: boolean,
  isEditable: boolean,
  isOpen: boolean,
  onOpenDiscardModal: () => void,
  onClose: () => void,
  uploadFormData: (data: any, resetFormData: UseFormReset<any>) => void;
}

const VacancySpecialityModal = ({
  title,
  initialInputValues,
  sectionValues = {},
  isOnEditing,
  isEditable,
  isOpen,
  onOpenDiscardModal,
  onClose,
  uploadFormData
}: IVacancySpecialityModal) => {
  const { validateLocationInput } = useLocationValidation()
  const profileTranslations = useSelector(selectProfileTranslations)
  const fieldsRefs = useRef<FieldsRefs>({} as FieldsRefs)
  const formMethods = useForm<IVacancySpecialityForm>({
    mode: isOnEditing ? 'onBlur' : 'onSubmit',
    defaultValues: initialInputValues,
    shouldFocusError: false,
    reValidateMode: 'onBlur'
  })
  const {
    register,
    formState,
    formState: {
      errors, isDirty, isSubmitting, isSubmitted
    },
    handleSubmit,
    clearErrors,
    setValue,
    getValues,
    setError,
    reset,
    trigger
  } = formMethods
  const { SECTIONS } = useVacancyForm({
    getValues, setError, clearErrors, trigger, formState
  })

  useEffect(() => {
    scrollToFirstErrorField()
  }, [isSubmitting])

  const scrollToFirstErrorField = () => {
    const firstErrorField = Object.values(FIELDS).filter((field: FIELDS) => errors[field])[0]
    if (firstErrorField && fieldsRefs.current[firstErrorField]) {
      fieldsRefs.current[firstErrorField]?.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      })
    }
  }

  const onOptionSelect = useCallback((fieldName: FIELDS, shouldClearErrors = false) => {
    return (option: string) => {
      setValue(fieldName, option, { shouldDirty: true })
      if (shouldClearErrors) clearErrors(fieldName)
    }
  }, [clearErrors])

  const getUIByType = ((
    type: INPUT_TYPES | undefined,
    {
      onBlur,
      onFocus,
      errorMessage,
      titleKey,
      maxCharCount,
      minCharCount,
      showMaxCharCount,
      fieldName,
      allVersions,
      isRequired,
      helperText,
      errorMsgKey,
      height,
      customComponent: CustomComponent,
      links,
      allowMultiple,
      defaultValue,
      placeholder,
      isSelectBox,
      numberOnly,
      customErrorMsgKey,
      isBorderRed,
      fieldErrorNames,
      width
    }: VacancyFieldsConfig &
      {
        fieldName: FIELDS, titleKey: string, placeholder?: string, isBorderRed?: boolean,
        fieldErrorNames?: string[], width?: number
      }
  ) => {
    if (CustomComponent) {
      return (
        <CustomComponent
          {...register(fieldName, {
            required: errorMsgKey ? profileTranslations[errorMsgKey] : false
          })}
          register={register}
          fieldName={fieldName}
          onOptionSelect={onOptionSelect(fieldName, true)}
          title={profileTranslations[titleKey]}
          placeholder={placeholder}
          helperText={helperText}
          error={errors[customErrorMsgKey || fieldName]?.message}
          maxCharCount={maxCharCount}
          minCharCount={minCharCount}
          showMaxCharCount={showMaxCharCount}
          errorMsg={errorMsgKey ? profileTranslations[errorMsgKey] : ''}
          isSubmitted={isSubmitted}
          isOnEditing={isOnEditing}
          isEditable={isEditable}
          isRequired={isRequired}
          isBorderRed={isBorderRed}
          defaultValue={defaultValue}
          links={links}
          allowMultiple={allowMultiple}
          numberOnly={numberOnly}
        />
      )
    }
    switch (type) {
      default: return <></>
      case INPUT_TYPES.INPUT:
      case INPUT_TYPES.TEXTAREA: {
        return (
          <FormInput
            clearErrors={clearErrors}
            placeholder={placeholder}
            helperText={helperText}
            title={profileTranslations[titleKey]}
            maxCharCount={maxCharCount}
            minCharCount={minCharCount}
            showMaxCharCount={showMaxCharCount}
            isRequired={isRequired}
            register={register}
            fieldName={fieldName}
            width={width || 146}
            fieldErrorNames={fieldErrorNames}
            defaultValue={defaultValue}
            error={errors[fieldName]?.message}
            isBorderRed={isBorderRed}
            errorMsg={errorMsgKey ? profileTranslations[errorMsgKey] : ''}
            isTextarea={type === INPUT_TYPES.TEXTAREA}
            height={height}
            setValue={setValue}
            isNumber={numberOnly || false}
            onBlur={onBlur}
            onFocus={onFocus}
            isSubmitted={isSubmitted}
            isOnEditing={isOnEditing}
          />
        )
      }
      case INPUT_TYPES.EXPENDABLE_INPUT: {
        return (
          <Controller
            name={fieldName}
            rules={{ required: errorMsgKey ? profileTranslations[errorMsgKey] : false }}
            render={() => {
              return (
                <ExpandableInput
                  onBlur={onBlur}
                  openPopupOnClick
                  title={profileTranslations[titleKey]}
                  placeholder={placeholder}
                  helperText={helperText}
                  options={allVersions || []}
                  defaultInputValue={defaultValue}
                  onOptionSelect={onOptionSelect(fieldName, true)}
                  isRequired={isRequired}
                  error={errorMessage ?? errors[fieldName]?.message}
                  isSelectElement={!!isSelectBox}
                  isBorderRed={isBorderRed}
                  id={fieldName}
                  maxCharCount={maxCharCount}
                  minCharCount={minCharCount}
                  showMaxCharCount={showMaxCharCount}
                />
              )
            }}
          />
        )
      }
    }
  })

  const onSubmit = (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault()
    const [, , , , locationInput] = e.target as unknown as { value: string }[]
    const locationFields = {
      [FIELDS.LOCATIONS]: getValues(FIELDS.LOCATIONS),
      [FIELDS.LOCATION_TYPE]: getValues(FIELDS.LOCATION_TYPE)
    } as IVacancySpecialityForm

    const groupFields = {
      [FIELDS.SALARY_MAX]: getValues(FIELDS.SALARY_MAX),
      [FIELDS.SALARY_MIN]: getValues(FIELDS.SALARY_MIN),
      [FIELDS.RATE]: getValues(FIELDS.RATE),
      [FIELDS.CURRENCY]: getValues(FIELDS.CURRENCY)
    } as IVacancySpecialityForm
    validateSalary(groupFields, setError, clearErrors, profileTranslations, trigger)
    validateLocationInput(locationFields, setError, clearErrors, profileTranslations, locationInput?.value)
    handleSubmit((data: any) => {
      return uploadFormData(data, reset)
    })()
  }

  const setFieldsRef = (ref: HTMLDivElement | null, fieldName: FIELDS) => {
    fieldsRefs.current[fieldName] = ref
  }

  const sections = {
    [FIELDS.JOB_NAME]: getValues(FIELDS.JOB_NAME),
    [FIELDS.LOCATIONS]: getValues(FIELDS.LOCATIONS),
    [FIELDS.JOB_TYPE]: getValues(FIELDS.JOB_TYPE),
    [FIELDS.ABOUT_JOB]: getValues(FIELDS.ABOUT_JOB),
    [FIELDS.TAGS]: getValues(FIELDS.TAGS),
    [FIELDS.LOCATION_TYPE]: getValues(FIELDS.LOCATION_TYPE),
    [FIELDS.AUDIENCE]: getValues(FIELDS.AUDIENCE),
    [FIELDS.SALARY_MAX]: getValues(FIELDS.SALARY_MAX),
    [FIELDS.SALARY_MIN]: getValues(FIELDS.SALARY_MIN),
    [FIELDS.RATE]: getValues(FIELDS.RATE),
    [FIELDS.CURRENCY]: getValues(FIELDS.CURRENCY),
    [FIELDS.URL]: getValues(FIELDS.URL)
  }

  const onCloseModal = () => {
    if (isDirty) {
      onOpenDiscardModal()
    } else {
      onClose()
    }
  }

  const renderSections =
    (
      sections: any,
      isSubmitted: boolean,
      isOnEditing: boolean,
      sectionValues: any,
      getValues: UseFormGetValues<IVacancySpecialityForm>,
      errors: any,
      setFieldsRef: (ref: HTMLDivElement | null, fieldName: FIELDS) => void
    ) => {
      return SECTIONS(sections, (isSubmitted || isOnEditing), sectionValues).map((field) => {
        if (field.fields && field.groupKey) {
          return (
            <Fragment key={field.groupKey}>
              <div
                className={field.groupKey === FIELDS.PAYMENT
                  ? styles.vacancyInputGroupPayment
                  : styles.vacancyInputGroup}
                ref={(ref) => setFieldsRef(ref, field.groupKey as FIELDS)}
              >
                {field.fields.map((element) => {
                  return (
                    <div
                      key={element.fieldName}
                      ref={(ref) => setFieldsRef(ref, element.fieldName as FIELDS)}
                    >
                      {getUIByType(element.type, element)}
                    </div>
                  )
                })}
              </div>
              {!!errors[field.groupKey]?.message && (
                <Typography
                  variant={TypographyVariants.Body_3_Medium}
                  tag="div"
                  className={styles.errorMessageSalary}
                >
                  {errors[field.groupKey]?.message?.replace(LOCALISE_SEPARATORS.DIGIT, field.errorMsgVariable || '')}
                </Typography>
              )}
            </Fragment>
          )
        }
        return (
          <div
            key={field.titleKey}
            ref={(ref) => setFieldsRef(ref, field.fieldName as FIELDS)}
          >
            {getUIByType(field.type, field as any)}
          </div>
        )
      })
    }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onCloseModal}
      width={VACANCY_MODAL_WIDTH}
      isFullScreenOnMobile
      childrenStyles={styles.childrenStyles}
    >
      <FormProvider {...formMethods}>
        <form className={styles.form} autoComplete="off" onSubmit={onSubmit}>
          <div className={styles.header}>
            <Typography variant={TypographyVariants.Desktop_UI_XL_Medium} tag="p" className={styles.title}>
              {title}
            </Typography>
            <div className={styles.close} onClick={onCloseModal}><CloseIcon /></div>
          </div>
          <div className={styles.content}>
            {renderSections(sections, isSubmitted, isOnEditing, sectionValues, getValues, errors, setFieldsRef)}
          </div>
          <ModalFooter
            footerStyles={styles.footer}
            primaryButtonText={profileTranslations.buttonTextPublish}
            secondaryButtonText={profileTranslations.buttonTextCancel}
            secondaryButtonOnClick={onCloseModal}
            isPrimaryButtonLoading={isSubmitting}
          />
        </form>
      </FormProvider>
    </Modal>
  )
}

export const VacancySpecialityModalWithDiscard = withDiscardModal(VacancySpecialityModal)
