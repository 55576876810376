import { useSelector } from 'react-redux'
import { CheckCircleIcon } from 'common/icons/CheckCircleIcon'
import { Modal } from 'features/Modal'
import { selectHomeTranslations, selectIntroduceSuccessTranslations } from 'features/Translations/selectors'
import styles from './styles.module.sass'

interface IExploreSuccessModal {
  message: string;
  iconColor: string;
  isOpen: boolean;
  onClose: () => void;
}

export const ExploreSuccessModal = ({
  message, iconColor, isOpen, onClose
}: IExploreSuccessModal) => {
  const introduceTranslations = useSelector(selectIntroduceSuccessTranslations)
  const homeTranslations = useSelector(selectHomeTranslations)

  return (
    <Modal
      isOpen={isOpen}
      title={<CheckCircleIcon color={iconColor} />}
      onClose={onClose}
      width={600}
      primaryButtonOnClick={onClose}
      primaryButtonText={introduceTranslations.exploreCreatedOkButton}
    >
      <div className={styles.exploreSuccessModal}>
        <p className={styles.title}>{homeTranslations.exploreCreatedTitle}</p>
        <p className={styles.message}>{message}</p>
      </div>
    </Modal>
  )
}
