import { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Popup from 'reactjs-popup'
import { SecondaryButton } from 'common/components/Button_V2/SecondaryButton'
import { ALERT_DISPLAY_DURATION_MS } from 'common/constants'
import { DotsVerticalIcon } from 'common/icons_V2/DotsVerticalIcon'
import { Share } from 'features/MyProfile/components/ProfilePopup/Share'
import { UnTrust } from 'features/MyProfile/components/ProfilePopup/Untrust'
import { selectMyNetworkContacts } from 'features/MyProfile/selectors'
import { ProfileType } from 'features/MyProfile/types'
import { selectHomeTranslations } from 'features/Translations/selectors'
import { Introduce } from './Introduce'
import styles from './styles.module.sass'

interface IProfilePopup {
  uid: string,
  profile?: ProfileType,
}

export const ProfilePopup: FC<IProfilePopup> = ({ uid, profile }) => {
  const homeTranslations = useSelector(selectHomeTranslations)
  const [isOpen, setIsOpen] = useState(false)
  const [showAlert, setShowAlert] = useState(false)
  const closePopup = () => setIsOpen(false)
  const contacts = useSelector(selectMyNetworkContacts)
  const isContact = contacts.some((contact) => contact.uid === uid)

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>
    if (showAlert) {
      timeoutId = setTimeout(() => {
        setShowAlert(false)
      }, ALERT_DISPLAY_DURATION_MS)
    }
    return () => {
      clearTimeout(timeoutId)
    }
  }, [showAlert])

  return (
    <div>
      <Popup
        trigger={(
          <div className={styles.popupTrigger}>
            <SecondaryButton
              title={<DotsVerticalIcon />}
              onClick={() => setIsOpen(true)}
            />
          </div>
        )}
        open={isOpen}
        position={['right top', 'left top']}
      >
        <div className={styles.menuButtons}>
          {!isContact && !!profile && (
            <Introduce closePopup={closePopup} profile={profile} />
          )}
          {!!profile && (
            <Share
              uid={uid}
              closePopup={closePopup}
              alertTrigger={() => setShowAlert(true)}
            />
          )}
          {isContact && (
            <UnTrust uid={uid} closePopup={closePopup} />
          )}
        </div>
      </Popup>
      <Popup
        trigger={(
          <div />
        )}
        open={showAlert}
        onClose={() => setShowAlert(false)}
        position={['right bottom', 'left bottom']}
        repositionOnResize
      >
        {showAlert && (
          <div className={styles.popupContent}>
            {/* TODO: make general. Now is only share specific */}
            {homeTranslations.exploreLinkCopyMessage}
          </div>
        )}
      </Popup>
    </div>
  )
}
