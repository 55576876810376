import { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import cn from 'classnames'
import { ROUTES } from 'common/constants'
import { actions as actionsFilter } from 'features/FilterItems_V2/actions'
import { selectUserTrustedById } from 'features/Home/selectors'
import { TrustingCount } from 'features/MyProfile/components/TrustedContacts/components/TrustingCount'
import { selectMyNetworkContacts } from 'features/MyProfile/selectors'
import { ContactsType, ProfileType } from 'features/MyProfile/types'
import { selectMyNetworkTranslations } from 'features/Translations/selectors'
import { OtherProfileTrustUsersModal } from 'features/TrustUsersModal/OtherProfileTrustUsersModal'
import { NetworkModalFilters } from 'features/TrustUsersModal/types'
import { useProfileSections } from 'providers/ProfileSectionsProvider'
import { OtherProfileTrustedContacts } from './OtherProfileTrustedContacts'
import styles from './styles.module.sass'

interface ITrustedContacts {
  isOtherProfile: boolean
  profile: ProfileType
  auth: boolean
}

export const TrustedContacts: FC<ITrustedContacts> = ({ profile, isOtherProfile, auth }) => {
  const dispatch = useDispatch()
  const { activeSection } = useProfileSections()
  const myNetworkTranslations = useSelector(selectMyNetworkTranslations)
  const contacts = useSelector(selectMyNetworkContacts)
  const trustedUsers = contacts.filter((user) => user.isTrusted)
  const trustedContactsOtherProfile = useSelector(selectUserTrustedById(profile?.uid || ''))

  const trustingContactsOtherProfile = profile?.contacts
    ? Object.keys(profile.contacts).reduce<ContactsType[]>((acc: any, uid) => {
      if (profile.trust.includes(uid)) {
        acc.push(profile?.contacts?.[uid as any])
      }
      return acc
    }, [])
    : []

  const [isOpenTrustModal, setIsOpenTrustModal] = useState(false)
  const history = useHistory()

  const getTrustedContacts = () => {
    return {
      trustedUsersForView: trustedUsers
        .sort(({ photo, photoURL }) => (photo || photoURL ? -1 : 1))
        .slice(0, 2),
      count: trustedUsers.length
    }
  }

  const trustedContacts = getTrustedContacts()
  const trustedMutualUsers = trustedContactsOtherProfile.filter((user) =>
    contacts.some((trustedUser) => trustedUser.uid === user.uid))

  const trustingMutualUsers = trustingContactsOtherProfile.filter((user) =>
    contacts.some((trustedUser) => trustedUser.uid === user.uid))

  const handleOpenModal = (filter: NetworkModalFilters, dataCount: number) => {
    if (dataCount) {
      dispatch(actionsFilter.setNetworkModalFilters({ networkModalFilters: [filter] }))
      setIsOpenTrustModal(true)
    }
  }

  const redirectNetworkPage = (dataCount: number) => {
    if (dataCount) {
      history.push(ROUTES.CONTACTS)
    }
  }

  return (
    <div className={styles.trustedUsersContainer}>
      {!isOtherProfile && (
        <div className={cn(styles.trustedUsers, !!activeSection && styles.disable)}>
          <div
            onClick={() => redirectNetworkPage(contacts.length)}
            className={cn(!contacts.length && styles.disable)}
          >
            <TrustingCount
              count={contacts.length}
              title={myNetworkTranslations.myProfileContacts}
              auth={auth}
            />
          </div>

          <div
            onClick={() => redirectNetworkPage(trustedContacts.count)}
            className={cn(!trustedContacts.count && styles.disable)}
          >
            <TrustingCount
              count={trustedContacts.count}
              title={myNetworkTranslations.trustingTitle}
              auth={auth}
            />
          </div>
        </div>
      )}

      {isOtherProfile && (
        <>
          <div className={styles.contactInfo}>
            <OtherProfileTrustedContacts
              trustedContactsOtherProfile={trustedContactsOtherProfile}
              setIsOpenTrustModal={() =>
                handleOpenModal(NetworkModalFilters.TRUSTED, trustedContactsOtherProfile.length)}
              title={myNetworkTranslations.trustedTitle}
              mutualUsers={trustedMutualUsers}
              auth={auth}
            />
            <OtherProfileTrustedContacts
              trustedContactsOtherProfile={trustingContactsOtherProfile}
              setIsOpenTrustModal={() =>
                handleOpenModal(NetworkModalFilters.TRUSTING, trustingContactsOtherProfile.length)}
              title={myNetworkTranslations.trustingTitle}
              mutualUsers={trustingMutualUsers}
              auth={auth}
            />
          </div>
          {(trustedContactsOtherProfile || trustingContactsOtherProfile) && (
            <OtherProfileTrustUsersModal
              trustedContactsOtherProfile={trustedContactsOtherProfile}
              trustingContactsOtherProfile={trustingContactsOtherProfile}
              isOpenTrustModal={isOpenTrustModal}
              onClose={() => setIsOpenTrustModal(false)}
              profile={profile}
            />
          )}
        </>
      )}
    </div>
  )
}
