import { useSelector } from 'react-redux'
import { ButtonType, Modal } from 'features/Modal'
import { selectOnboardingTranslations, selectProfileTranslations, selectSettingsTranslations } from 'features/Translations/selectors'
import styles from './styles.module.sass'

interface IDeleteAllDataModal {
  isOpen: boolean
  onClose: () => void
  onSubmit: () => void
  isDeleteLoading: boolean;
}

export const DeleteAllDataModal = ({
  isOpen, onClose, onSubmit, isDeleteLoading
}: IDeleteAllDataModal) => {
  const settingsTranslations = useSelector(selectSettingsTranslations)
  const profileTranslations = useSelector(selectProfileTranslations)
  const onboardingTranslations = useSelector(selectOnboardingTranslations)

  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      width={600}
      title={settingsTranslations.deleteProfile}
      primaryButtonOnClick={onClose}
      primaryButtonText={onboardingTranslations.cancelButtonText}
      secondaryButtonOnClick={onSubmit}
      secondaryButtonText={profileTranslations.deleteButton}
      isSecondaryButtonLoading={isDeleteLoading}
      secondaryButtonType={ButtonType.DANGER}
    >
      <div className={styles.body}>
        <p>{settingsTranslations.profileDeleteMessage}</p>
        <p>{settingsTranslations.profileDeleteAreYouSureMessage}</p>
      </div>
    </Modal>
  )
}
