import { FC, useRef, useState } from 'react'
import cn from 'classnames'
import { useMobileMediaQuery } from 'common/hooks/mediaQuery'
import { useOutside } from 'common/hooks/useOutside'
import { HamburgerMenuIcon } from 'common/icons/HamburgerMenuIcon'
import { Settings } from 'features/Settings'
import styles from './styles.module.sass'

export const SideBar = () => {
  const dropDownListEventRef = useRef(null)
  const [isOpenSideMenu, setIsOpenSideMenu] = useState(false)
  const toggleSideBar = () => setIsOpenSideMenu(!isOpenSideMenu)
  const closeSideBar = () => setIsOpenSideMenu(false)
  const isMobile = useMobileMediaQuery()

  return (
    <>
      <div
        className={cn(styles.container, isMobile && styles.hamburgerMenu)}
        ref={dropDownListEventRef}
        onClick={toggleSideBar}
      >
        {isMobile ? <HamburgerMenuIcon /> : <HamburgerMenuIcon stroke="#030D21" />}
      </div>
      {isOpenSideMenu && <SideMenu isOpenSideMenu={isOpenSideMenu} closeSideBar={closeSideBar} />}
    </>
  )
}

interface ISideMenu {
  isOpenSideMenu: boolean
  closeSideBar: () => void
}

export const SideMenu: FC<ISideMenu> = ({ isOpenSideMenu, closeSideBar }) => {
  const sideMenuEventRef = useRef(null)
  useOutside(sideMenuEventRef, closeSideBar)

  return (
    <nav className={cn(styles.sideDrawer, isOpenSideMenu && styles.open)} ref={sideMenuEventRef}>
      <Settings isMobileScreen closeSideBar={closeSideBar} />
    </nav>
  )
}
