import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { ContactList } from 'features/Contacts/Network/MyNetwork/components/ContactList'
import { selectTrustedUids } from 'features/MyProfile/selectors'
import { UserType } from 'features/MyProfile/types'

interface IDefaultState {
  searchString: string
  sortedContacts: UserType[]
  trustedListFirstLevel: UserType[]
  trustedListSecondLevel: UserType[]
  openedProfileUid: string
  helperForTrusting: any
  trustedContactsFirstRef: React.RefObject<HTMLDivElement>,
  trustedContactsSecondRef: React.RefObject<HTMLDivElement>,
  allContactsRef: React.RefObject<HTMLDivElement>,
  firstLevelContactsTitle: string,
  secondLevelContactsTitle: string,
  allContactsTitle: string
}

export const DefaultState: FC<IDefaultState> = ({
  searchString,
  sortedContacts,
  trustedListFirstLevel,
  trustedListSecondLevel,
  openedProfileUid,
  helperForTrusting,
  trustedContactsFirstRef,
  trustedContactsSecondRef,
  allContactsRef,
  firstLevelContactsTitle,
  secondLevelContactsTitle,
  allContactsTitle
}) => {
  const trustedUids = useSelector(selectTrustedUids)
  return (
    <>
      {!!trustedListFirstLevel.length && (
        <div ref={trustedContactsFirstRef}>
          <ContactList
            title={firstLevelContactsTitle}
            contacts={trustedListFirstLevel}
            emptyHelper={helperForTrusting}
            searchString={searchString}
            openedProfileUid={openedProfileUid}
            trustedUids={trustedUids}
          />
        </div>
      )}
      {!!trustedListSecondLevel.length && (
        <div ref={trustedContactsSecondRef}>
          <ContactList
            title={secondLevelContactsTitle}
            contacts={trustedListSecondLevel}
            searchString={searchString}
            openedProfileUid={openedProfileUid}
            trustedUids={trustedUids}
          />
        </div>
      )}
      {!!sortedContacts.length && (
        <div ref={allContactsRef}>
          <ContactList
            title={allContactsTitle}
            contacts={sortedContacts}
            searchString={searchString}
            openedProfileUid={openedProfileUid}
            trustedUids={trustedUids}
          />
        </div>
      )}
    </>
  )
}
