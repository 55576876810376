import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useLocation } from 'react-router-dom'
import cn from 'classnames'
import { ROUTE_PARAMS, ROUTES } from 'common/constants'
import { AuthIcon } from 'common/icons/AuthIcon'
import { ExploreIcon } from 'common/icons/ExploreIcon'
import { MailIcon } from 'common/icons/MailIcon'
import { Unlock } from 'common/icons/Unlock'
import { UsersIcon } from 'common/icons/UsersIcon'
import { ListingFilledIcon } from 'common/icons_V2/ListingFilledIcon'
import { ListingIcon } from 'common/icons_V2/ListingIcon'
import { getQueryObject } from 'common/utils/url'
import { getIsAdminMode } from 'features/Admin/selectors'
import { getAuth } from 'features/Auth/selectors'
import { getIsMessagesHasUnread, getOpenedChat } from 'features/Conversations/selectors'
import { actions as actionsFilter } from 'features/FilterItems_V2/actions'
import { getMyUid } from 'features/MyProfile/selectors'
import { actions as actionsSearch } from 'features/SearchInput_V2/actions'
import { NAVBAR_KEYS, SEARCH_PARAMS } from 'features/Translations/constants'
import { selectNavBarTranslations } from 'features/Translations/selectors'
import { useSetPageScrollYPositionContext } from 'providers/SetPageScrollYPositionProvider'
import styles from './styles.module.sass'

interface IPage {
  url: string;
  title: string;
  icon: React.ReactNode;
  activeIcon?: React.ReactNode;
  hasNotification: boolean;
  hideOnMobile: boolean;
}

const pagesForUnauthorized: IPage[] = [
  {
    url: ROUTES.AUTH,
    title: 'Auth',
    icon: <AuthIcon />,
    hasNotification: false,
    hideOnMobile: false
  }
]

const pagesAdmin: IPage[] = [
  {
    url: ROUTES.ADMIN,
    title: 'Admin',
    icon: <Unlock />,
    hasNotification: false,
    hideOnMobile: false
  }
]

export const NavBar = () => {
  const dispatch = useDispatch()
  const auth = useSelector(getAuth)
  const myUid = useSelector(getMyUid)
  const isAdminMode = useSelector(getIsAdminMode)
  const navBarTranslations = useSelector(selectNavBarTranslations)
  const location = useLocation()
  const isMessagesHasUnread = useSelector(
    (state) => getIsMessagesHasUnread(state, location.pathname === NAVBAR_KEYS.MESSAGES)
  )
  const openChat = useSelector(getOpenedChat)
  const isHideNavbar = location.pathname === NAVBAR_KEYS.MESSAGES && !!openChat
  const { setPageScrollYPosition, resetPageScrollYPosition } = useSetPageScrollYPositionContext()
  const searchParams = getQueryObject(location.search)

  const pages: IPage[] = [
    {
      url: NAVBAR_KEYS.EXPLORE,
      title: navBarTranslations.homeTextNavBar,
      icon: <ExploreIcon />,
      hasNotification: false,
      hideOnMobile: false
    },
    {
      url: NAVBAR_KEYS.MY_NETWORK,
      title: navBarTranslations.networkTextNavBar,
      icon: <UsersIcon />,
      hasNotification: false,
      hideOnMobile: false
    },
    {
      url: NAVBAR_KEYS.MESSAGES,
      title: navBarTranslations.chatTextNavBar,
      icon: <MailIcon />,
      hasNotification: isMessagesHasUnread,
      hideOnMobile: false
    },
    {
      url: NAVBAR_KEYS.LISTINGS.replace(ROUTE_PARAMS.UID, myUid),
      title: navBarTranslations.profileTextNavBar,
      icon: <ListingIcon />,
      activeIcon: <ListingFilledIcon />,
      hasNotification: false,
      hideOnMobile: false
    },
    ...(isAdminMode ? pagesAdmin : [])
  ]

  if (!auth) {
    return (
      <div className={`${styles.container} ${styles.unauthorized}`}>
        {pagesForUnauthorized.map(({ url, title, icon }) => (
          <NavLink
            key={title}
            to={url}
            className={styles.navLink}
            activeClassName={styles.activeLink}
          >
            {icon}
          </NavLink>
        ))}
      </div>
    )
  }

  const handlePageScrollAndFilters = (url: string) => {
    if (location.pathname === ROUTES.EXPLORE && url === NAVBAR_KEYS.EXPLORE) {
      resetPageScrollYPosition()
      dispatch(actionsFilter.setHomeFilters({ homeFilters: [] }))
      dispatch(actionsFilter.setListingFilters({ listingFilters: [] }))
      dispatch(actionsSearch.setHomeSearch({ homeSearch: '' }))
    }

    if (location.pathname === ROUTES.EXPLORE && url !== NAVBAR_KEYS.EXPLORE) {
      setPageScrollYPosition()
    }
  }
  const whitelistedParams = [
    SEARCH_PARAMS.HOME_SELECTED_CARD,
    SEARCH_PARAMS.LISTING_SELECTED_CARD,
    SEARCH_PARAMS.ARCHIVED_SELECTED_CARD
  ]
  const searchP = whitelistedParams.reduce((acc, param) => {
    let newVal = acc
    if (searchParams[param]) {
      if (newVal) newVal += '&'
      if (!newVal) newVal += '?'
      const str = `${param}=${searchParams[param]}`
      newVal += str
    }
    return newVal
  }, '')

  return (
    <div className={styles.gradientBg}>
      <div className={cn(styles.container, isHideNavbar && styles.hideNavbar)}>
        {pages.map(({
          url, title, icon, activeIcon, hideOnMobile, hasNotification
        }) => (
          <NavLink
            key={url}
            to={`${url}${searchP}`}
            className={cn(styles.navLink, hideOnMobile && styles.hideOnMobile)}
            activeClassName={title === 'Admin' ? styles.activeLinkAdmin : styles.activeLink}
            onClick={() => handlePageScrollAndFilters(url)}
            exact
          >
            <div className={styles.iconContainer}>
              {hasNotification && <div className={styles.messageUnreadIcon} />}
              {location.pathname === url ? activeIcon || icon : icon}
            </div>
            <div className={styles.navBarTitle}>{title}</div>
            <div className={styles.border} />
          </NavLink>
        ))}
      </div>
    </div>
  )
}
