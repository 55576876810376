import React, { FC } from 'react'
import {
  Controller,
  ControllerRenderProps,
  FieldValues,
  useFormContext
} from 'react-hook-form'
import { useSelector } from 'react-redux'
import cn from 'classnames'
import { JOB_TYPE_KEYS } from 'common/constants'
import { FIELDS } from 'common/enums'
import { CheckMarkIcon } from 'common/icons_V2/CheckMarkIcon'
import { Typography, TypographyVariants } from 'common/typography'
import { IVacancySpecialityForm } from 'features/MyProfile/components/VacancySpeciality_V2/validator'
import { selectErrorMsgsTranslations, selectVacanciesTranslations } from 'features/Translations/selectors'
import styles from './styles.module.sass'

const locationsTypeTranslations = {
  remote: JOB_TYPE_KEYS.REMOTE,
  onsite: JOB_TYPE_KEYS.ON_SITE,
  hybrid: JOB_TYPE_KEYS.HYBRID
}

interface ILocationListField {
  title: string
  error?: string
  isSubmitted?: boolean
  isOnEditing?: string
}

export const LocationListField: FC<ILocationListField> = ({
  title, error, isSubmitted, isOnEditing
}) => {
  const vacanciesTranslations = useSelector(selectVacanciesTranslations)
  const errorMsgsTranslations = useSelector(selectErrorMsgsTranslations)
  const formContext = useFormContext<IVacancySpecialityForm>()
  const {
    clearErrors, trigger, getValues, setValue, setError, watch
  } = formContext

  const {
    [FIELDS.LOCATION_TYPE]: locationsType
  } = watch()

  const onChange = (
    value: boolean,
    fieldValues: ControllerRenderProps<FieldValues, string>
  ) => {
    fieldValues.onChange(value)

    setValue(FIELDS.LOCATION_TYPE, {
      ...locationsType,
      [fieldValues.name]: value
    })

    if (isSubmitted || isOnEditing) {
      trigger([FIELDS.LOCATIONS])
      const updatedLocationsType = getValues(FIELDS.LOCATION_TYPE)
      if (Object.values(updatedLocationsType).every((locationType) => !locationType)) {
        setError(FIELDS.LOCATION_TYPE, { type: 'custom', message: errorMsgsTranslations.locationTypeError })
      } else {
        clearErrors(FIELDS.LOCATION_TYPE)
      }
    }
  }

  return (
    <div className={styles.locationTypes}>
      <Typography
        variant={TypographyVariants.Body_2_Medium}
        tag="label"
        className={cn(error && styles.invalidTitle)}
      >
        {title}<span>*</span>
      </Typography>
      <div className={cn(
        styles.locationType,
        error && styles.isInvalid
      )}
      >
        {Object.entries(locationsType).map(([key, value]) => (
          <Controller
            key={key}
            name={key}
            rules={{
              validate: (_, values) => {
                if (Object.values(values[FIELDS.LOCATION_TYPE]).every((locationType) => !locationType)) {
                  const message = errorMsgsTranslations.locationTypeError
                  setError(FIELDS.LOCATION_TYPE, { type: 'custom', message })
                  return message
                }
                return true
              }
            }}
            render={({ field }) => (
              <div
                className={cn(styles.button, value && styles.selected)}
                onClick={() => onChange(!value, field)}
              >
                {value && (
                  <span className={styles.checkmark}>
                    <CheckMarkIcon />
                  </span>
                )}
                <Typography variant={TypographyVariants.Body_2_Regular} tag="span">
                  {vacanciesTranslations[
                    (locationsTypeTranslations as unknown as typeof vacanciesTranslations)[key]
                  ]}
                </Typography>
              </div>
            )}
          />
        ))}
      </div>
      {error && (
        <Typography variant={TypographyVariants.Body_3_Medium} tag="div" className={styles.inputError}>
          {error}
        </Typography>
      )}
    </div>
  )
}
