import { FC, ReactNode, useEffect } from 'react'
import cn from 'classnames'
import { PrimaryButton } from 'common/components/Button_V2/PrimaryButton'
import { TransparentButton } from 'common/components/Button_V2/TransparentButton'
import { PreloaderIcon } from 'common/icons/PreloaderIcon'
import { CloseIcon } from 'common/icons_V2/CloseIcon'
import { Typography, TypographyVariants } from 'common/typography'
import styles from './styles.module.sass'

export enum ButtonType {
  DEFAULT,
  DANGER
}

interface IModalFooter {
  buttonType?: ButtonType;
  secondaryButtonType?: ButtonType;
  primaryButtonText?: string
  primaryButtonOnClick?: (e: any) => void // TODO
  isPrimaryButtonDisabled?: boolean
  isPrimaryButtonLoading?: boolean
  footerStyles?: string
  buttonStyles?: string
  secondaryButtonText?: string;
  secondaryButtonOnClick?: () => void;
  isSecondaryButtonDisabled?: boolean;
  isSecondaryButtonLoading?: boolean;
}

interface IModal extends Partial<IModalFooter> {
  title?: string | undefined | ReactNode
  isOpen: boolean
  applyContainerMobileStyles?: boolean
  onClose: () => void
  children?: ReactNode
  childrenStyles?: string
  headerStyles?: string
  width?: number | string
  height?: number | string
  scrollableContent?: ReactNode
  footerWithScrollableContent?: ReactNode
  isFullScreenOnMobile?: boolean
  isLoading?: boolean
  isClosable?: boolean
  containerStyles?: string
}

export const ModalFooter: FC<IModalFooter> = ({
  buttonType = 'default',
  secondaryButtonType = 'default',
  footerStyles,
  primaryButtonText,
  buttonStyles,
  primaryButtonOnClick,
  isPrimaryButtonDisabled,
  isPrimaryButtonLoading,
  secondaryButtonText,
  secondaryButtonOnClick,
  isSecondaryButtonDisabled,
  isSecondaryButtonLoading
}) => {
  if (!primaryButtonText) return <></>
  return (
    <div className={cn(footerStyles, styles.footer)}>
      {secondaryButtonText && (
        <TransparentButton
          title={(
            <Typography variant={TypographyVariants.Body_1_Medium} tag="p">
              {secondaryButtonText}
            </Typography>
          )}
          onClick={secondaryButtonOnClick}
          className={cn(
            styles.ghostButton,
            secondaryButtonType === ButtonType.DANGER && styles.dangerSecondaryButton
          )}
          disabled={isSecondaryButtonDisabled || isSecondaryButtonLoading}
          isLoading={isSecondaryButtonLoading}
        />
      )}
      <PrimaryButton
        type="submit"
        title={(
          <Typography variant={TypographyVariants.Body_1_Medium} tag="p">
            {primaryButtonText}
          </Typography>
        )}
        className={cn(
          buttonStyles || styles.primaryButton,
          buttonType === ButtonType.DANGER && styles.dangerPrimaryButton
        )}
        onClick={primaryButtonOnClick}
        disabled={isPrimaryButtonDisabled || isPrimaryButtonLoading}
        isLoading={isPrimaryButtonLoading}
      />
    </div>
  )
}

export const Modal: FC<IModal> = ({
  title,
  isOpen,
  onClose,
  children,
  childrenStyles,
  headerStyles,
  width = 560,
  height,
  applyContainerMobileStyles = true,
  primaryButtonText,
  primaryButtonOnClick,
  isPrimaryButtonDisabled,
  isPrimaryButtonLoading,
  scrollableContent,
  footerWithScrollableContent,
  footerStyles,
  buttonStyles,
  isFullScreenOnMobile = false,
  secondaryButtonText,
  secondaryButtonOnClick,
  isSecondaryButtonDisabled,
  isSecondaryButtonLoading,
  buttonType,
  secondaryButtonType,
  isLoading = false,
  isClosable = false,
  containerStyles
}) => {
  useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : 'auto'
    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [isOpen])

  const handleStopPropagation = (e: any) => {
    e.stopPropagation()
  }

  if (!isOpen) return null
  return (
    <div className={cn(styles.wrapper, isFullScreenOnMobile && styles.mobileWrapper)} onClick={handleStopPropagation}>
      <div
        className={cn(
          styles.container,
          (isFullScreenOnMobile && applyContainerMobileStyles) && styles.mobileContainer,
          containerStyles
        )}
        style={{ width, height }}
      >
        {(title || isClosable) && (
          <div className={cn(styles.header, headerStyles)}>
            <div className={styles.title}>
              {title}
              {isLoading && <div className={styles.loading}><PreloaderIcon stroke="#D7DFED" /></div>}
            </div>
            <div className={styles.close} onClick={onClose}><CloseIcon /></div>
          </div>
        )}
        {children && (
          <div className={cn(styles.children, childrenStyles)}>
            {children}
          </div>
        )}
        {scrollableContent && (
          <div className={styles.scrollableContent}>
            {scrollableContent}
          </div>
        )}
        {footerWithScrollableContent || (
          <ModalFooter
            buttonType={buttonType}
            secondaryButtonType={secondaryButtonType}
            footerStyles={footerStyles}
            primaryButtonText={primaryButtonText}
            buttonStyles={buttonStyles}
            primaryButtonOnClick={primaryButtonOnClick}
            isPrimaryButtonDisabled={isPrimaryButtonDisabled}
            isPrimaryButtonLoading={isPrimaryButtonLoading}
            secondaryButtonText={secondaryButtonText}
            secondaryButtonOnClick={secondaryButtonOnClick || onClose}
            isSecondaryButtonDisabled={isSecondaryButtonDisabled}
            isSecondaryButtonLoading={isSecondaryButtonLoading}
          />
        )}
      </div>
    </div>
  )
}
