import { useState } from 'react'
import { useSelector } from 'react-redux'
import { ExploreSuccessModal } from 'features/MyProfile/components/VacancySpeciality_V2/ExploreSuccessModal'
import { ManageSpecialityModal } from 'features/MyProfile/components/VacancySpeciality_V2/ManageSpecialityModal'
import { selectHomeTranslations } from 'features/Translations/selectors'

interface ISpecialityModalWithOkButton {
  isOpen: boolean,
  onClose: (limitReached: boolean) => void,
  specialityId?: string,
}

export const ManageSpecialityModalWithOkButton = ({ isOpen, onClose, specialityId = '' }: ISpecialityModalWithOkButton) => {
  const homeTranslations = useSelector(selectHomeTranslations)
  const [isOpenExploreSuccesModal, setIsOpenExploreSuccessModal] = useState(false)

  return (
    <>
      {isOpen && (
        <ManageSpecialityModal
          isOpen={isOpen}
          specialityId={specialityId}
          onClose={onClose}
          onOpenSuccessModal={() => setIsOpenExploreSuccessModal(true)}
        />
      )}
      <ExploreSuccessModal
        message={homeTranslations.exploreOpenToCreatedAlertMessage}
        iconColor="#0EB200"
        isOpen={isOpenExploreSuccesModal}
        onClose={() => setIsOpenExploreSuccessModal(false)}
      />
    </>
  )
}
