import {
  useCallback,
  useEffect,
  useMemo, useRef, useState
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { Contact } from 'common/components/Contact'
import { EmptySearchState } from 'common/components/EmptySearchState_V2'
import { useMobileMediaQuery, useTabletMediaQuery } from 'common/hooks/mediaQuery'
import { searchInString } from 'common/utils/searchInString'
import { MOCK_USER } from 'features/Contacts/Network/constants'
import { DefaultState } from 'features/Contacts/Network/MyNetwork/components/DefaultState'
import { NetworkHeader } from 'features/Contacts/Network/NetworkHeader'
import { WelcomeContacts } from 'features/Contacts/Network/WelcomeContacts'
import { FilterItems } from 'features/FilterItems_V2'
import { actions as actionsFilter } from 'features/FilterItems_V2/actions'
import { selectNetworkFilters } from 'features/FilterItems_V2/selectors'
import {
  getIsEditTrustContacts,
  selectIsLoadingTrustOrUntrust,
  selectMyNetworkContacts,
  selectTrustedUids,
  selectTrustLevels
} from 'features/MyProfile/selectors'
import { SearchInput } from 'features/SearchInput_V2'
import {
  selectErrorMsgsTranslations, selectMyNetworkTranslations, selectMyProfileTranslations, selectProfileTranslations
} from 'features/Translations/selectors'
import styles from './styles.module.sass'

enum NetworkFilters {
  FIRST_LEVEL = 1,
  SECOND_LEVEL = 2,
  ALL = 3
}

export const MyNetwork = () => {
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const history = useHistory()
  const [, , openedProfileUid] = pathname.split('/')
  const contacts = useSelector(selectMyNetworkContacts)
  const myNetworkTranslations = useSelector(selectMyNetworkTranslations)
  const profileTranslations = useSelector(selectProfileTranslations)
  const myProfileTranslations = useSelector(selectMyProfileTranslations)
  const trustLevelsWithoutMocUser = useSelector(selectTrustLevels)
  const isLoading = useSelector(selectIsLoadingTrustOrUntrust)
  const networkFilters = useSelector(selectNetworkFilters)
  const isMobile = useMobileMediaQuery()
  const isTablet = useTabletMediaQuery()
  const trustLevels = Object.values(trustLevelsWithoutMocUser).some((element) => element === 1)
    ? trustLevelsWithoutMocUser : { [MOCK_USER]: 1, ...trustLevelsWithoutMocUser }
  const [filteredContacts, setFilteredContacts] = useState(contacts)
  const [searchString, setSearchString] = useState('')
  const errorMsgsTranslations = useSelector(selectErrorMsgsTranslations)
  const trustedUids = useSelector(selectTrustedUids)
  const isEditTrustContacts = useSelector(getIsEditTrustContacts)

  const trustedContactsFirstRef = useRef<HTMLDivElement>(null)
  const trustedContactsSecondRef = useRef<HTMLDivElement>(null)
  const allContactsRef = useRef<HTMLDivElement>(null)

  const helperForTrusting = {
    displayName: profileTranslations.emptyTrustingHelperTitle,
    photoURL: '',
    uid: MOCK_USER,
    job: {
      title: profileTranslations.emptyTrustingHelperSubtitle, description: '', currency: '', onsite: false, remote: false, payPeriod: ''
    },
    photo: '',
    isTrusted: true,
    first_name: '',
    last_name: '',
    slots: '',
    about: ''
  }

  const sortedContacts = useMemo(() => {
    return filteredContacts.sort((firstUser, secondUser) => {
      if (trustLevels[firstUser.uid] === trustLevels[secondUser.uid]) {
        return firstUser.displayName < secondUser.displayName ? -1 : 1
      }
      return trustLevels[firstUser.uid] - trustLevels[secondUser.uid]
    })
  }, [filteredContacts, trustLevels, contacts])

  const firstLevelContacts = filteredContacts.filter(({ uid }: any) => trustedUids.includes(uid))
  const secondLevelContacts = filteredContacts.filter(({ uid }: any) => trustLevels[uid] === 2)
  const firstLevelContactsTitle = `${myNetworkTranslations.firstLevel} (${firstLevelContacts.length}/${profileTranslations.maxCountTrusts})`
  const secondLevelContactsTitle = `${myNetworkTranslations.secondLevel} (${secondLevelContacts.length}/${sortedContacts.length})`
  const allContactsTitle = `${myNetworkTranslations.others} (${sortedContacts.length})`

  const getNetworkFilters = () => {
    const filters = []
    if (firstLevelContacts.length) {
      filters.push({ id: NetworkFilters.FIRST_LEVEL, name: myNetworkTranslations.networkFiltersFirst })
    }
    if (secondLevelContacts.length) {
      filters.push({ id: NetworkFilters.SECOND_LEVEL, name: myNetworkTranslations.networkFiltersSecond })
    }
    if (sortedContacts.length) {
      filters.push({ id: NetworkFilters.ALL, name: myNetworkTranslations.networkFiltersAll })
    }
    return filters
  }

  useEffect(() => {
    if (isEditTrustContacts) {
      trustedContactsFirstRef?.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [isEditTrustContacts])

  const onChange = (searchString: string) => {
    const filteredContacts = !searchString.length ? contacts : contacts.filter((contact) => {
      return searchInString(searchString, contact.displayName || '')
        || searchInString(searchString, contact.job?.title || '')
        || searchInString(searchString, contact.job?.company || '')
        || searchInString(searchString, contact?.phoneNumber || '')
    })
    setSearchString(searchString)
    setFilteredContacts(filteredContacts)
  }

  useEffect(() => {
    if (searchString) {
      setSearchString('')
    }
    setFilteredContacts(contacts)
  }, [contacts])

  useEffect(() => {
    if (history.action === 'POP') {
      handleSetNetworkFilters(networkFilters)
    } else {
      clearNetworkFilters()
    }
  }, [history.action])

  const clearNetworkFilters = useCallback(() => {
    dispatch(actionsFilter.setNetworkFilters({ networkFilters: [] }))
  }, [dispatch])

  const getSearchedState = useMemo(() => {
    if (!searchString) return null
    return (
      <DefaultState
        searchString={searchString}
        sortedContacts={filteredContacts}
        trustedListFirstLevel={firstLevelContacts}
        trustedListSecondLevel={secondLevelContacts}
        openedProfileUid={openedProfileUid}
        helperForTrusting={helperForTrusting}
        trustedContactsFirstRef={trustedContactsFirstRef}
        trustedContactsSecondRef={trustedContactsSecondRef}
        allContactsRef={allContactsRef}
        firstLevelContactsTitle={firstLevelContactsTitle}
        secondLevelContactsTitle={secondLevelContactsTitle}
        allContactsTitle={allContactsTitle}
      />
    )
  }, [searchString, filteredContacts])

  const handleSetNetworkFilters = (filters: number[]) => {
    dispatch(actionsFilter.setNetworkFilters({ networkFilters: filters }))

    setTimeout(() => {
      if (filters.includes(NetworkFilters.FIRST_LEVEL)) {
        trustedContactsFirstRef?.current?.scrollIntoView({ behavior: 'smooth' })
      } else if (filters.includes(NetworkFilters.SECOND_LEVEL)) {
        trustedContactsSecondRef?.current?.scrollIntoView({ behavior: 'smooth' })
      } else if (filters.includes(NetworkFilters.ALL)) {
        allContactsRef?.current?.scrollIntoView({ behavior: 'smooth' })
      }
    }, 0)
  }

  const isShowSearchInput: boolean = !((isMobile || isTablet) && !sortedContacts.length && !searchString.length)
  const isShowEmptyStateImage: boolean = (isMobile || isTablet) && !sortedContacts.length && !searchString.length

  return (
    <>
      <div className={styles.container}>
        <div className={styles.headerNavBar}>
          <NetworkHeader
            pageName={myNetworkTranslations.title}
            subTitle={myNetworkTranslations.subtitle}
            isLoading={isLoading}
          />
          {isShowSearchInput && (
            <>
              <SearchInput
                onChange={onChange}
                className={styles.searchContainer}
                value={searchString}
                placeholder={myNetworkTranslations.search}
              />
              {!!filteredContacts.length && (
                <FilterItems
                  items={getNetworkFilters()}
                  setActiveFilters={handleSetNetworkFilters}
                  activeFilters={networkFilters}
                  filtersContent={styles.filtersContent}
                  allowMultiple={false}
                />
              )}
            </>
          )}
        </div>
        <>
          {isShowEmptyStateImage ? (
            <WelcomeContacts />
          ) : (
            <>
              {(filteredContacts.length) ? (
                <div className={styles.listContainer}>
                  {searchString ? getSearchedState : (
                    <DefaultState
                      searchString={searchString}
                      sortedContacts={sortedContacts}
                      trustedListFirstLevel={firstLevelContacts}
                      trustedListSecondLevel={secondLevelContacts}
                      openedProfileUid={openedProfileUid}
                      helperForTrusting={helperForTrusting}
                      trustedContactsFirstRef={trustedContactsFirstRef}
                      trustedContactsSecondRef={trustedContactsSecondRef}
                      allContactsRef={allContactsRef}
                      firstLevelContactsTitle={firstLevelContactsTitle}
                      secondLevelContactsTitle={secondLevelContactsTitle}
                      allContactsTitle={allContactsTitle}
                    />
                  )}
                </div>
              ) : (
                <>
                  {(!searchString.length && !sortedContacts.length) ? (
                    <>
                      <p className={styles.trustingCountText}>
                        {myProfileTranslations.trustedText} {`0/${profileTranslations.maxCountTrusts}`}
                      </p>
                      <Contact
                        isTrusted={false}
                        onClick={() => null}
                        searchString={searchString}
                        name={helperForTrusting.displayName || helperForTrusting.last_name}
                        photoUrl={helperForTrusting.photoURL}
                        photo={helperForTrusting.photo}
                        jobTitle={helperForTrusting.job?.title || ''}
                        active={false}
                        uid={helperForTrusting.uid}
                        isMocUser
                      />
                    </>
                  ) : (
                    <div className={styles.listContainer}>
                      <EmptySearchState
                        emptySearchSubTitle={errorMsgsTranslations.emptyNetworkSearchStateSubTitle}
                        isBackgroundWhite
                      />
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </>
      </div>
    </>
  )
}
