import { FC, lazy, PropsWithChildren } from 'react'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { ROUTES } from 'common/constants'
import envConfig from 'config'
import { getAuth } from 'features/Auth/selectors'
import { getParamsPublicProfile } from 'features/Contacts/selectors'
import { Header } from 'features/Header'
import { LayoutBody } from 'features/Layout/LayoutBody'
import { SetPageScrollYPositionProvider } from 'providers/SetPageScrollYPositionProvider'
import styles from './styles_V2.module.sass'

const VideoChat = envConfig.features?.callEnabled ?
  lazy(() => import('features/VideoChat').then(({ VideoChat }) => ({ default: VideoChat })))
  : () => <></>

export const Layout: FC<PropsWithChildren<{}>> = ({ children }) => {
  const auth = useSelector(getAuth)
  const paramsPublicProfile = useSelector(getParamsPublicProfile)

  if (auth === false && !paramsPublicProfile) return <Redirect to={ROUTES.AUTH} />

  return (
    <SetPageScrollYPositionProvider pageRoute={ROUTES.EXPLORE}>
      <div className={styles.wrapper}>
        <Header />
        <LayoutBody>{children}</LayoutBody>
        {envConfig.features?.callEnabled && (
          <VideoChat />
        )}
      </div>
    </SetPageScrollYPositionProvider>
  )
}
