import { STATUS_VACANCIES } from 'common/constants'
import type { SpecialtyOffer, VacancyOfferOrFavorType } from 'features/Conversations/types'
import type { ActionTypes, ProfileType, VacancyType } from './types'

const initialState = {
  profile: null as ProfileType | null,
  isActiveFcm: false,
  isRegistration: null as Boolean | null,
  loaders: [] as string[],
  progressLoadingFile: null as number | null,
  vacancies: [] as VacancyType[],
  isVacanciesLoading: true as boolean,
  specialities: {} as any,
  favoredVacancies: [] as VacancyOfferOrFavorType[],
  favoredSpecialties: [] as SpecialtyOffer[],
  offeredVacancies: [] as VacancyOfferOrFavorType[],
  isFullTrustContacts: false,
  isOpenContactsModal: false,
  trustLevels: {} as {[key: string]: number},
  commonTrustedContactList: {} as {[uid: string]: string[]},
  isTrustOrUntrustLoading: false as boolean,
  isEditTrustContacts: false as boolean
}

export const ProfileReducer = (state = initialState, action: ActionTypes): typeof initialState => {
  switch (action.type) {
    case 'PROFILE__SET_MY_PROFILE': {
      const contacts = action.profile?.contacts && Array.isArray(action.profile?.contacts)
        ? action.profile.contacts
        : Object.keys(action.profile?.contacts || {})

      return {
        ...state,
        isRegistration: !action.profile,
        profile: action.profile ? {
          ...action.profile,
          contacts: action.profile?.contacts ? contacts : []
        } : null
      }
    }
    case 'PROFILE__ADD_CONTACT': {
      return {
        ...state,
        profile: {
          ...state.profile,
          contacts: [...(state.profile?.contacts || []), action.contactUid]
        } as ProfileType
      }
    }
    case 'PROFILE__ADD_CONTACTS': {
      return {
        ...state,
        profile: {
          ...state.profile,
          contacts: [...(state.profile?.contacts || []), ...action.contactUids]
        } as ProfileType
      }
    }
    case 'PROFILE__SET_MY_PROFILE_TRUST': {
      return {
        ...state,
        profile: { ...state.profile, trust: action.trustedUids } as ProfileType
      }
    }
    case 'PROFILE__SET_MY_PROFILE_IS_FULL_TRUST_CONTACTS': {
      return {
        ...state,
        profile: { ...state.profile, isFullTrustContacts: action.isFullTrustContacts } as ProfileType
      }
    }
    case 'PROFILE__SET_MY_PROFILE_EDIT_TRUST_CONTACTS': {
      return {
        ...state,
        profile: { ...state.profile, isEditTrustContacts: action.isEditTrustContacts } as ProfileType
      }
    }
    case 'PROFILE__SET_MY_PROFILE_IS_OPEN_CONTACTS_MODAL': {
      return {
        ...state,
        isOpenContactsModal: action.isOpenContactsModal
      }
    }
    case 'PROFILE__SET_TRUST_OR_UNTRUST_LOADING': {
      return {
        ...state,
        isTrustOrUntrustLoading: action.isLoading
      }
    }
    case 'PROFILE__DELETE_MY_CONTACT': {
      return {
        ...state,
        profile: {
          ...state.profile,
          contacts: state.profile?.contacts?.filter((contactUid) => contactUid !== action.payload)
        } as ProfileType
      }
    }
    case 'PROFILE__UPDATE_MY_PROFILE_PHOTO':
      return {
        ...state,
        profile: {
          ...state.profile,
          photoURL: action.photoURL,
          photo: action.photo
        } as ProfileType
      }
    case 'PROFILE__TOGGLE_LOADER': {
      let newLoaders: string[] = []

      if (state.loaders.includes(action.loader)) {
        newLoaders = state.loaders.filter((loader) => loader !== action.loader)
      } else {
        newLoaders = [...state.loaders, action.loader]
      }

      return {
        ...state,
        loaders: newLoaders
      }
    }
    case 'PROFILE__SET_IS_ACTIVE_FCM':
      return {
        ...state,
        isActiveFcm: action.isActiveFcm
      }
    case 'PROFILE__UPDATE_MY_SLOTS': {
      if (!state.profile) return state
      let updatedSlots = { ...state.profile?.slots }

      if (action.payload.action === 'add') {
        if (updatedSlots) {
          if (typeof action.payload.slot !== 'string') {
            updatedSlots = {
              ...updatedSlots,
              ...action.payload.slot
            }
            return {
              ...state,
              profile: {
                ...state.profile,
                slots: updatedSlots
              }
            }
          }
        }
      }
      if (typeof action.payload.slot === 'string') {
        delete updatedSlots[action.payload.slot]
        return {
          ...state,
          profile: {
            ...state.profile,
            slots: updatedSlots
          }
        }
      }
      return state
    }
    case 'PROFILE__SET_MY_VACANCIES_LOADING': {
      return {
        ...state,
        isVacanciesLoading: action.isLoading
      }
    }
    case 'PROFILE__SET_MY_VACANCIES': {
      return {
        ...state,
        vacancies: action?.vacancies,
        isVacanciesLoading: false
      }
    }
    case 'PROFILE__SET_TRUST_LEVELS': {
      return {
        ...state,
        trustLevels: action.trustLevels
      }
    }
    case 'PROFILE__SET_COMMON_TRUSTED_CONTACTS': {
      return {
        ...state,
        commonTrustedContactList: action.commonTrustedContactList
      }
    }
    case 'PROFILE__SET_ALL_MY_FAVORS_FOR_VACANCIES': {
      return {
        ...state,
        favoredVacancies: action?.favors
      }
    }
    case 'PROFILE__SET_ALL_OFFERS_FOR_VACANCIES': {
      return {
        ...state,
        offeredVacancies: action?.offers
      }
    }
    case 'PROFILE__SET_MY_FAVOR_FOR_VACANCY': {
      return {
        ...state,
        favoredVacancies: [
          ...state.favoredVacancies,
          action?.favor
        ]
      }
    }
    case 'PROFILE__ADD_NEW_VACANCY': {
      return {
        ...state,
        vacancies: [...state.vacancies, action?.vacancy]
      }
    }
    case 'PROFILE__EDIT_VACANCY': {
      const updatedVacancies = state.vacancies.map((vacancy) => {
        if (vacancy.id === action?.vacancy.id) {
          return {
            ...action.vacancy,
            ts: vacancy.ts,
            updated: new Date().toISOString()
          }
        }
        return vacancy
      })
      return {
        ...state,
        vacancies: updatedVacancies
      }
    }
    case 'PROFILE__CLOSE_VACANCY': {
      const updatedVacancies = state.vacancies.map((vacancy) => {
        if (vacancy.id === action.vacancyId) {
          return { ...vacancy, status: STATUS_VACANCIES.CLOSED }
        }
        return vacancy
      })
      return {
        ...state,
        vacancies: updatedVacancies
      }
    }
    case 'PROFILE__CLOSE_SPECIALITY': {
      const { uid, specialityId } = action.payload
      const updatedSpecialities = state.specialities[uid].data.map((speciality: any) => {
        if (speciality.id === specialityId) {
          return { ...speciality, status: STATUS_VACANCIES.CLOSED }
        }
        return speciality
      })
      return {
        ...state,
        specialities: {
          ...state.specialities,
          [uid]: {
            ...state.specialities[uid],
            data: updatedSpecialities
          }
        }
      }
    }
    case 'PROFILE__DELETE_VACANCY': {
      const updatedVacancies = state.vacancies.filter((vacancy) => vacancy.id !== action.vacancyId)
      return {
        ...state,
        vacancies: updatedVacancies
      }
    }
    case 'PROFILE__SET_SPECIALITIES': {
      return {
        ...state,
        specialities: {
          ...state.specialities,
          [action.payload.uid]: {
            isLoaded: true,
            data: action.payload.specialities
          }
        }
      }
    }
    case 'PROFILE__ADD_NEW_SPECIALITY': {
      return {
        ...state,
        specialities: {
          ...state.specialities,
          [action.payload.uid]: {
            isLoaded: true,
            data: [...(state.specialities[action.payload.uid] ?? {})?.data ?? [], action.payload.speciality]
          }
        }
      }
    }
    case 'PROFILE__DELETE_SPECIALITY': {
      return {
        ...state,
        specialities: {
          ...state.specialities,
          [action.payload.uid]: {
            isLoaded: true,
            data: state.specialities[action.payload.uid].data.filter((speciality: any) => {
              return speciality.id !== action.payload.specialityId
            })
          }
        }
      }
    }
    case 'PROFILE__EDIT_SPECIALITY': {
      const updatedSpecialities = state.specialities[action.payload.uid].data.map((speciality: any) => {
        if (speciality.id === action.payload.speciality.id) {
          return {
            ...action.payload.speciality,
            ts: speciality.ts,
            updated: new Date().toISOString()
          }
        }
        return speciality
      })
      return {
        ...state,
        specialities: {
          ...state.specialities,
          [action.payload.uid]: {
            isLoaded: true,
            data: updatedSpecialities
          }
        }
      }
    }
    case 'PROFILE__SET_ALL_MY_FAVORS_FOR_SPECIALTIES': {
      return {
        ...state,
        favoredSpecialties: action?.favors
      }
    }
    case 'PROFILE__ADD_MY_FAVOR_FOR_SPECIALTY': {
      return {
        ...state,
        favoredSpecialties: [
          ...state.favoredSpecialties,
          action?.favor
        ]
      }
    }
    default: return state
  }
}
