export const CheckCircleIcon = ({ color = '#030D21', width = 48 }: { color?: string; width?: number }) => (
  <svg width={width} height={width} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_19068_18462)">
      <path d="M18.0004 35.7991C8.18586 35.7991 0.201172 27.8144 0.201172 17.9999C0.201172 8.18537 8.18586 0.200684 18.0004 0.200684C27.8149 0.200684 35.7995 8.18537 35.7995 17.9999C35.7995 27.8144 27.8149 35.7991 18.0004 35.7991ZM18.0004 3.01318C9.73667 3.01318 3.01367 9.73618 3.01367 17.9999C3.01367 26.263 9.73667 32.9866 18.0004 32.9866C26.2635 32.9866 32.987 26.2636 32.987 17.9999C32.987 9.73618 26.264 3.01318 18.0004 3.01318Z" fill={color} />
      <path d="M15.0188 24.8363C14.6588 24.8363 14.2988 24.699 14.0243 24.4245L9.12604 19.5263C8.57648 18.9773 8.57648 18.0868 9.12604 17.5378C9.67504 16.9883 10.566 16.9883 11.1145 17.5378L15.0188 21.4416L24.8862 11.5748C25.4357 11.0252 26.3256 11.0252 26.8746 11.5748C27.4242 12.1238 27.4242 13.0142 26.8746 13.5632L16.0127 24.4245C15.7382 24.699 15.3782 24.8363 15.0188 24.8363Z" fill={color} />
    </g>
    <defs>
      <clipPath id="clip0_19068_18462">
        <rect width="36" height="36" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
